import React, { useEffect } from "react";
import Navbar from "../Common/Navbar";
import Service from "../Images/Construction.jpg";
import Image1 from "../Images/card1.jpg";
import Image2 from "../Images/card2.jpg";
import Image3 from "../Images/card3.jpg";
import Image4 from "../Images/card4.jpg";
import Team1 from "../Images/team5 (1).png";
import Team2 from "../Images/team22 (1).png";
import Team3 from "../Images/team3.png";
import Team4 from "../Images/team33 (1).png";
// import Team5 from "../Images/t1.png";
import Team11 from "../Images/t1.png";
import Director from "../Images/director.png";
import HorizontalScroll from "../Views/HorizontalScroll";
import Green from "../Images/green.jpg";
import "../CSS/about.css";

const About = () => {
  useEffect(() => {
    localStorage.setItem("currentPage", "About");
  }, []);
  const teamMembers = [
    {
      imageUrl: Team11,
      name: "Harprit singh khasria",
      designation: "All rounder",
    },
    { imageUrl: Team1, name: "Rajnish", designation: "Site operative" },
    { imageUrl: Team2, name: "Virang Patel", designation: "Electrician" },
    { imageUrl: Team3, name: "Narender kumar", designation: "Tiller" },
    { imageUrl: Team4, name: "Gaurav kumar", designation: "Site operative" },
  ];
  return (
    <>
      <section className="">
        <img src={Service} alt="service" className="servicebackground" />

        <div className="d-flex align-items-center breadcrumbs">
          <div
            class="container position-relative align-items-center mt-5 d-flex flex-column h-100"
            data-aos="fade"
          >
            <h1 className=" service-head text-light">About us</h1>
            {/* <ol>
                            <li><a href="/">Home</a></li>
                            <li className="text-light">About</li>
                        </ol> */}
          </div>
        </div>
      </section>

      <section className=" bg-dark text-light py-5 ">
        <section>
          <div className="container">
            <h1 className="mt-4 text-center p">Our Expertise</h1>
            <div className="row py-5">
              <div className="col-sm-6 web">
                <img src={Image1} alt="image" height="80%" width="85%" />
              </div>
              <div className="col-sm-6 expertise-image mobile">
                <img src={Image1} alt="image" height="80%" width="85%" />
              </div>
              <div className="col-sm-6 ">
                <h5>
                  Welcome to Chouhan Builders Midlands Ltd., where craftsmanship
                  and innovation converge to shape extraordinary constructions.
                  With 15 years of excellence, we're your trusted partner for
                  both residential sanctuaries and commercial landmarks. From
                  cozy homes to bustling business hubs, we bring expertise and
                  dedication to every project.
                </h5>
                <h5>
                  In the realm of residential construction, we specialize in
                  crafting personalized spaces that reflect our clients' unique
                  lifestyles and preferences. For commercial ventures, we
                  prioritize functionality and aesthetics, designing spaces that
                  inspire productivity and leave a lasting impression.
                </h5>
                <h5>
                  At Chouhan Builders Midlands, client satisfaction is
                  paramount. We prioritize clear communication and collaboration
                  throughout the process, ensuring that your vision is realized
                  to perfection. Join us in building a better future, one
                  structure at a time.
                </h5>
              </div>
            </div>

            <h1 className="mt-4 text-center  p">Our Approach</h1>
            <br />
            <h5>
              At the heart of our success lies a commitment to excellence in
              everything we do. From the initial concept to the final
              inspection, we prioritize transparency, communication, and
              collaboration to ensure a seamless experience for our clients.
              With a focus on innovation and sustainability, we embrace the
              latest technologies and practices to minimize environmental impact
              and maximize efficiency.
            </h5>
            <div className="row mt-5 ">
              <div className="col-sm-4">
                <img src={Image2} alt="image" height="85%" width="100%" />
              </div>
              <div className="col-sm-4">
                <img src={Image3} alt="image" height="85%" width="100%" />
              </div>
              <div className="col-sm-4">
                <img src={Image4} alt="image" height="85%" width="100%" />
              </div>
            </div>
          </div>
        </section>

        {/* <section>
          <div className="container py-5  p">
            <br />
            <h1 className="text-center ">Our Values</h1>
            <br />
            <div className="d-flex mt-4">
              <div>
                <ul className="fs-5 values">
                  <h5>
                    <span className="text-gold"> - Quality:</span> We are
                    committed to maintaining the highest standards of quality in
                    every project we undertake.
                  </h5>
                  <br />
                  <br />
                  <h5>
                    <span className="text-gold"> - Integrity:</span>  We believe
                    in conducting our business with honesty and transparency.
                  </h5>
                  <br />
                  <br />
                  <h5>
                    <span className="text-gold"> - Customer Satisfaction:</span>{" "}
                    Our clients are at the heart of everything we do. We aim to
                    build lasting relationships based on trust and mutual
                    respect.
                  </h5>
                  <br />
                  <br />
                  <h5>
                    <span className="text-gold"> - Innovation:</span> We
                    continuously seek new and better ways to meet the needs of
                    our clients.
                  </h5>
                </ul>
              </div>

              <div className="image">
                <img src={Green} alt="image" height="100%" width="100%" />
              </div>
            </div>
          </div>
        </section> */}

        <section>
          <div class="container py-5 mobile">
            <div class="text-center">
              <h1 className="text-center">Our Values</h1>
            </div>

            <br />

            <div class="row mt-4">
              <div class="col-md-6">
                <ul class="values">
                  <li>
                    <h4>
                      <span class="text-gold">Quality:</span>
                    </h4>
                    <p>
                      We are committed to maintaining the highest standards of
                      quality in every project we undertake.
                    </p>
                  </li>
                  <li>
                    <h4>
                      <span class="text-gold">Integrity:</span>
                    </h4>
                    <p>
                      We believe in conducting our business with honesty and
                      transparency.
                    </p>
                  </li>
                  <li>
                    <h4>
                      <span class="text-gold">Customer Satisfaction:</span>
                    </h4>
                    <p>
                      Our clients are at the heart of everything we do. We aim
                      to build lasting relationships based on trust and mutual
                      respect.
                    </p>
                  </li>
                  <li>
                    <h4>
                      <span class="text-gold">Innovation:</span>
                    </h4>
                    <p>
                      We continuously seek new and better ways to meet the needs
                      of our clients.
                    </p>
                  </li>
                </ul>
              </div>

              <div class="col-md-6 expertise-image ">
                <img
                  src={Green}
                  alt="image"
                  class="img-fluid"
                  height="20%"
                  width="90%"
                />
              </div>
            </div>
          </div>
          <div class="container py-5 web">
            <div class="text-center">
              <h1 className="text-center">Our Values</h1>
            </div>

            <br />

            <div class="d-flex">
              <div class="col-md-6">
                <ul class="values">
                  <li>
                    <h4>
                      <span class="text-gold">Quality:</span>
                    </h4>
                    <p>
                      We are committed to maintaining the highest standards of
                      quality in every project we undertake.
                    </p>
                  </li>
                  <li>
                    <h4>
                      <span class="text-gold">Integrity:</span>
                    </h4>
                    <p>
                      We believe in conducting our business with honesty and
                      transparency.
                    </p>
                  </li>
                  <li>
                    <h4>
                      <span class="text-gold">Customer Satisfaction:</span>
                    </h4>
                    <p>
                      Our clients are at the heart of everything we do. We aim
                      to build lasting relationships based on trust and mutual
                      respect.
                    </p>
                  </li>
                  <li>
                    <h4>
                      <span class="text-gold">Innovation:</span>
                    </h4>
                    <p>
                      We continuously seek new and better ways to meet the needs
                      of our clients.
                    </p>
                  </li>
                </ul>
              </div>

              <div class="col-md-6  ">
                <img
                  src={Green}
                  alt="image"
                  class="img-fluid"
                  height="20%"
                  width="90%"
                />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mt-5 p web">
            <h1 className="text-center">Our Team</h1>
            <br />
            <p className="text-center">
              We look after our staff and subcontractors to get the best out of
              them, and we deliver!
            </p>
            <br />
            <div className="d-flex team justify-content-between">
              {teamMembers.map((team) => (
                <div>
                  <img
                    className="about-image"
                    src={team.imageUrl}
                    alt="image"
                    height="70%"
                    width="70%"
                  />
                  <div className="d-flex mt-4 flex-column align-items-center justify-content-center space">
                    <h4>{team.name}</h4>
                    <h5 className="text-center">{team.designation}</h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* for mobile view */}
          <div className="container mt-5 p mobile">
            <h1 className="text-center">Our Team</h1>
            <br />
            <p className="text-center">
              We look after our staff and subcontractors to get the best out of
              them. And we deliver!
            </p>
            <br />
            <div className="container">
              <div className="row">
                {teamMembers.map((team, index) => (
                  <div
                    key={index}
                    className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4 d-flex flex-column align-items-center"
                  >
                    <img
                      className="about-image img-fluid"
                      src={team.imageUrl}
                      alt={team.name}
                      style={{ height: "60", width: "60%" }}
                    />
                    <div className="mt-4 text-center">
                      <h3>{team.name}</h3>
                      <h5>{team.designation}</h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container my-5 py-3">
            <div
              className="row my-5"
              style={{
                border: "1px solid",
                borderColor: "white",
                borderRadius: "8px",
              }}
            >
              <div className="col-sm-6">
                <img
                  src={Director}
                  alt="image"
                  height="100%"
                  width="100%"
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div className="col-sm-6 my-3 p">
                <h1>Director</h1>
                <h5>
                  Welcome to Chouhan Builders Midlands Ltd. As the Managing
                  Director, I am thrilled to share our journey and commitment
                  with you. With over 15 years of experience in the construction
                  industry, I have had the privilege of leading a team that is
                  dedicated to excellence, innovation, and customer
                  satisfaction.
                </h5>
                <h5 className="mt-3">
                  Our approach is simple yet profound: we listen to your needs,
                  plan meticulously, execute with precision, and support you
                  throughout the process. We believe in building lasting
                  relationships based on trust, integrity, and mutual respect.
                </h5>
                <h5 className="mt-3">
                  Thank you for considering Chouhan Builders Midlands for your
                  construction needs. Together, we can create spaces that
                  inspire and endure.
                </h5>

                <h4
                  style={{
                    float: "right",
                    fontFamily: "cursive",
                    // fontSize: "larger",
                  }}
                  className="mt-2"
                >
                  Mr. Sunil Chouhan
                </h4>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container my-5 test">
            <h1 className="text-center">Testimonial</h1>
            <HorizontalScroll />
          </div>
        </section>

        <section>
          <div className="container p">
            <div className="row">
              <h1 className="text-center">WHY CHOOSE US?</h1>
              <h5 className="mt-3">
                <span className="text-gold"> Experience:</span> With a number of
                successfully completed projects under our belt, we have the
                knowledge and expertise to handle projects of any size and
                complexity.
              </h5>
              <h5 className="mt-3">
                <span className="text-gold">Quality:</span>We never compromise
                on quality. From materials to craftsmanship, we uphold the
                highest standards to deliver results that stand the test of
                time.
              </h5>
              <h5 className="mt-3">
                <span className="text-gold">Customer-Centric: </span>Your
                satisfaction is our top priority. We listen closely to your
                needs and preferences, offering personalized solutions that
                exceed your expectations.
              </h5>
              <h5 className="mt-3">
                <span className="text-gold">Value:</span>
                Your satisfaction is our top priority. We listen closely to your
                needs and preferences, offering personalized solutions that
                exceed your expectations.
              </h5>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};
export default About;
