import React, { useState, useEffect, useRef } from "react";
import Card1 from "../Images/review1.png";
import Card2 from "../Images/t2.png";
import Card3 from "../Images/human-icon.png";
import Card4 from "../Images/t4.png";
import Card5 from "../Images/t5.jpg";
import Card6 from "../Images/card6.webp";
import Card7 from "../Images/card7.webp";
import Card8 from "../Images/card8.webp";
import Card9 from "../Images/card9.webp";
import Card10 from "../Images/card10.webp";

const HorizontalScrollCards = ({ cards, cardWidth, autoScrollSpeed }) => {
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      const scrollWidth = container.scrollWidth;
      const clientWidth = container.clientWidth;
      const startAutoScroll = () => {
        intervalRef.current = setInterval(() => {
          const newScrollLeft = container.scrollLeft + 1;
          if (newScrollLeft >= scrollWidth - clientWidth) {
            container.scrollLeft = 0;
          } else {
            container.scrollLeft = newScrollLeft;
          }
        }, autoScrollSpeed);
      };
      const stopAutoScroll = () => {
        clearInterval(intervalRef.current);
      };
      container.addEventListener("mouseenter", stopAutoScroll);
      container.addEventListener("mouseleave", startAutoScroll);
      startAutoScroll();
      return () => {
        container.removeEventListener("mouseenter", stopAutoScroll);
        container.removeEventListener("mouseleave", startAutoScroll);
        clearInterval(intervalRef.current);
      };
    }
  }, [autoScrollSpeed]);

  return (
    <div
      ref={containerRef}
      className="horizontal-scroll-container"
      style={{ overflowX: "auto", whiteSpace: "nowrap" }}
    >
      {cards.map((card, index) => (
        <div
          key={index}
          className="testimonial-card"
          style={{
            display: "inline-block",
            width: cardWidth,
            margin: "0 10px",
          }}
        >
          {card}
        </div>
      ))}
    </div>
  );
};

const HorizontalScroll = () => {
  const initialCards = [
    {
      imageUrl: Card1,
      name: "Mr. Kasore khan",
      comment:
        "The dedication and craftsmanship of the construction team exceeded our expectations. Our dream home has been brought to life with exceptional attention to detail.",
    },
    {
      imageUrl: Card2,
      name: "Mr. Faisal",
      comment:
        " Working with the team was a seamless experience from start to finish. They delivered our project on time and within budget, and the quality of work was phenomenal.",
    },
    {
      imageUrl: Card3,
      name: "Dr. Mary ",
      comment:
        "We are thrilled with the renovation results. The team's professionalism and commitment to excellence truly made the process stress-free. Our heartfelt thanks!",
    },
    {
      imageUrl: Card3,
      name: "Mr. William jacob ",
      comment:
        "Our commercial project was expertly handled. The innovative solutions and outstanding communication made the journey a pleasure. Highly recommended!",
    },
    {
      imageUrl: Card3,
      name: "Dr. John wicks ",
      comment:
        "The construction team's expertise and customer service set them apart. Our new space has transformed the way we work, and we couldn't be more satisfied.",
    },
  ];
  const cards = [...initialCards, ...initialCards];
  return (
    <div className="mt-5 testimonials-home w-20 h-50">
      <HorizontalScrollCards
        cards={cards.map((card, index) => (
          <div key={index} className="text-center pb-5">
            <img
              src={card.imageUrl}
              alt={card.heading}
              className=" mt-3 border"
              style={{ width: "40%", height: "10%" }}
            />
            <h3 className="mt-2 text-center">{card.name}</h3>
            <h6>{card.position}</h6>
            <p className="text-dark testimonial-text">{card.comment}</p>
          </div>
        ))}
        cardWidth="280px"
        autoScrollSpeed={20}
      />
    </div>
  );
};

export default HorizontalScroll;
