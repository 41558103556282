import { React, useEffect, useState } from "react";
import "../CSS/contact.css";
import ContactImage from "../Images/contactus2.png";
import Navbar from "../Common/Navbar";
import MapComponent from "../Common/MapComponent";

const Contact = () => {
  useEffect(() => {
    localStorage.setItem("currentPage", "Contact Us");
  }, []);

  return (
    <>
      <img
        src={ContactImage}
        alt="image"
        className="background-image"
        height="100%"
        width="100%"
      />

      <br />
      <h1 className="text-center text-light contact">CONTACT US</h1>
      <main id="main">
        {/* <!-- ======= Contact Section ======= --> */}
        <section id="contact" className="section-bg mt-5">
          <div className="container" data-aos="fade-up">
            <div className="row text-light">
              <div className="col-sm-6">
                <div className="section-header">
                  <h2>Send Your Query</h2>
                  <p>Please provide more information so I can assist you</p>
                </div>

                <div className="d-flex justify-content-between contact-info">
                  <div>
                    <div className="contact-address">
                      <i className="bi bi-geo-alt"></i>
                      <h3>Address</h3>
                      <address>
                        Unit 1-3 Salisbury Street, Widnes, England, WA8 6PJ
                      </address>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="contact-phone">
                    <i className="bi bi-phone"></i>
                    <h3>Phone Number</h3>
                    <p>
                      <a href="tel:+155895548855"> 07473785314</a>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <div className="contact-email">
                      <i className="bi bi-envelope"></i>
                      <h3>Email</h3>
                      <p>
                        <a href="mailto:info@example.com">
                          info@chouhanbuilders.co.uk
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="form">
                  <form>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Your Name"
                          required
                        />
                      </div>
                      <div className="form-group col-md-6 mt-3 mt-md-0">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Your Email"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group mt-3">
                      <input
                        type="text"
                        className="form-control"
                        id="subject"
                        placeholder="Subject"
                        required
                      />
                    </div>
                    <div className="form-group mt-3">
                      <input
                        type="number"
                        placeholder="mobile Number"
                        class="form-control"
                      />
                    </div>
                    <div className="form-group mt-3">
                      <select
                        className="form-control"
                        id="propertyType"
                        required
                      >
                        <option value="">Project Type</option>
                        <option value="residential">Residential</option>
                        <option value="commercial">Commercial</option>
                      </select>
                    </div>
                    <div className="form-group mt-3">
                      <input
                        type="text"
                        className="form-control"
                        id="address"
                        placeholder="Address"
                        required
                      />
                    </div>
                    <div className="form-group mt-3">
                      <textarea
                        className="form-control"
                        rows="5"
                        placeholder="Message"
                        required
                      ></textarea>
                    </div>
                    {/* <div className="my-3">
                                            <div className="loading">Loading</div>
                                            <div className="error-message"></div>
                                            <div className="sent-message">Your message has been sent. Thank you!</div>
                                        </div> */}
                    <div>
                      <button type="submit" className="my-5 submit">
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="map-container">
                  <MapComponent />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Contact Section --> */}
      </main>
    </>
  );
};
export default Contact;
