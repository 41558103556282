import "./App.css";
import Home from "./Components/Home";
import Services from "./Components/Services";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Commercial from "./Components/Commercial";
import Residencial from "./Components/Residencial";
import Project from "./Components/Project";
import Footer from "./Common/Footer";
import Navbar from "./Common/Navbar";
import { useEffect } from "react";
import PrivacyComponent from "./Components/Privacy";
import Terms from "./Components/Terms";

function App() {
  useEffect(() => {
    document.title = `${localStorage.getItem(
      "currentPage"
    )} - Chouhan Builders`;
  }, []);
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/commercial" element={<Commercial />} />
          <Route path="/residencial" element={<Residencial />} />
          <Route path="/project" element={<Project />} />
          <Route path="/privacy-policy" element={<PrivacyComponent />} />
          <Route path="/Terms" element={<Terms />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
