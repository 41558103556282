import React, { useEffect, useState } from "react";
import "../CSS/home.css"; // Optional: You can style your carousel in a separate CSS file

const Carousel = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000); // Auto transition every 3 seconds

    return () => clearInterval(interval);
  }, [currentSlide]);

  const nextSlide = () => {
    const nextIndex = (currentSlide + 1) % images.length;
    setCurrentSlide(nextIndex);
  };

  const prevSlide = () => {
    const prevIndex = (currentSlide - 1 + images.length) % images.length;
    setCurrentSlide(prevIndex);
  };

  return (
    <div className="carousel">
      {/* <button className="prev-button" onClick={prevSlide}>
        &lt;
      </button> */}
      <img src={images[currentSlide]} alt={`Slide ${currentSlide}`} />
      {/* <button className="next-button" onClick={nextSlide}>
        &gt;
      </button> */}
    </div>
  );
};

export default Carousel;
