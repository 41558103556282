import React, { useEffect } from "react";
import Navbar from "../Common/Navbar";
import "../CSS/services.css";

import Service from "../Images/back.jpg";
import Service1 from "../Images/service1.jpg";
import Service2 from "../Images/service2.jpg";
import Service3 from "../Images/service3.jpg";
import Service4 from "../Images/service4.jpg";
import Service5 from "../Images/service5.jpg";
import Service6 from "../Images/service6.jpg";
import Service7 from "../Images/service7.jpg";
import Service8 from "../Images/service8.jpg";
import Image from "../Images/service123.jpg";
import Image2 from "../Images/service456.jpg";

const Services = () => {
  useEffect(() => {
    localStorage.setItem("currentPage", "Services");
  }, []);
  return (
    <>
      <section>
        <img src={Service} alt="service" className="servicebackground" />

        <div className="d-flex align-items-center breadcrumbs ">
          <div
            class="container position-relative d-flex flex-column align-items-center"
            data-aos="fade"
          >
            <h1 className=" my-5 service-head text-light">Services</h1>
            {/* <ol>
                            <li><a href="/">Home</a></li>
                            <li>Services</li>
                        </ol> */}
          </div>
        </div>
      </section>

      <div className="bg-dark text-light">
        <section>
          <div class="container pt-5 web">
            <h1 className="text-center">Our Core Services</h1>
            <h5>
              At Chouhan Builders Midlands Ltd., we are committed to delivering
              exceptional construction services for both residential and
              commercial projects. Our experienced team is dedicated to ensuring
              that every project is completed to the highest standards of
              quality, on time, and within budget. Discover our comprehensive
              range of services below.
            </h5>
            <div class="row py-5">
              <div class="col-sm-3 mx-5  px-2 my-2 py-2 s1 ">
                <div class="service-card">
                  <div class="service-card-inner">
                    <div class="service-card-front">
                      <img
                        src={Service1}
                        alt="sevice1"
                        height="100%"
                        width="100%"
                        className="service-img"
                      />
                    </div>
                    <div class="service-card-back">
                      <h3>Build House:</h3>
                      <p>
                        Crafting your dream home with precision and passion.
                        From foundation to finishing touches, we bring your
                        vision to life with quality craftsmanship and attention
                        to detail.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-3 mx-5  px-2 my-2 py-2 s1">
                <div class="service-card">
                  <div class="service-card-inner">
                    <div class="service-card-front">
                      <img
                        src={Service2}
                        alt="sevice1"
                        height="100%"
                        width="100%"
                        className="service-img"
                      />
                    </div>
                    <div class="service-card-back">
                      <h3>Electrical: </h3>
                      <p>
                        Expert electrical solutions for your safety and
                        convenience. From wiring to lighting, our skilled
                        electricians ensure reliable performance and compliance
                        with industry standards.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3 mx-5  px-2 my-2 py-2 s1">
                <div class="service-card">
                  <div class="service-card-inner">
                    <div class="service-card-front">
                      <img
                        src={Service3}
                        alt="sevice1"
                        height="100%"
                        width="100%"
                        className="service-img"
                      />
                    </div>
                    <div class="service-card-back">
                      <h3>Kitchen: </h3>
                      <p>
                        Elevate your culinary space with our kitchen remodeling
                        services. From custom cabinetry to modern fixtures, we
                        transform your kitchen into a stylish and functional hub
                        for cooking and entertaining.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3 mx-5  px-2 my-2 py-2 s1">
                <div class="service-card">
                  <div class="service-card-inner">
                    <div class="service-card-front">
                      <img
                        src={Service4}
                        alt="sevice1"
                        height="100%"
                        width="100%"
                        className="service-img"
                      />
                    </div>
                    <div class="service-card-back">
                      <h3> Bathroom: </h3>
                      <p>
                        Indulge in luxury and relaxation with our bathroom
                        renovation expertise. From elegant fixtures to spa-like
                        features, we create a serene oasis tailored to your
                        taste and lifestyle.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-3 mx-5  px-2 my-2 py-2 s1">
                <div class="service-card">
                  <div class="service-card-inner">
                    <div class="service-card-front">
                      <img
                        src={Service5}
                        alt="sevice1"
                        height="100%"
                        width="100%"
                        className="service-img"
                      />
                    </div>
                    <div class="service-card-back">
                      <h3>Loft & property Conversion:</h3>
                      <p>
                        Unlock the potential of your home with a loft
                        conversion. From extra bedrooms to home offices, we
                        maximize space and functionality with innovative design
                        and skilled construction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3 mx-5  px-2 my-2 py-2 s1">
                <div class="service-card">
                  <div class="service-card-inner">
                    <div class="service-card-front">
                      <img
                        src={Service6}
                        alt="sevice1"
                        height="100%"
                        width="100%"
                        className="service-img"
                      />
                    </div>
                    <div class="service-card-back">
                      <h3>Extentions:</h3>
                      <p>
                        Expand your living space with a seamless home extension.
                        From planning to construction, we enhance your home's
                        footprint while preserving its architectural integrity
                        and style.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-sm-3 mx-5  px-2 my-2 py-2 s1">
                <div class="service-card">
                  <div class="service-card-inner">
                    <div class="service-card-front">
                      <img
                        src={Service8}
                        alt="sevice1"
                        height="100%"
                        width="100%"
                        className="service-img"
                      />
                    </div>
                    <div class="service-card-back">
                      <h3> Plumbing and General Building: </h3>
                      <p>
                        Comprehensive building services for all your
                        construction needs. From plumbing installations to
                        full-scale renovations, we deliver quality workmanship
                        and personalized solutions to enhance your home.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-3 mx-5  px-2 my-2 py-2 s1">
                <div class="service-card">
                  <div class="service-card-inner">
                    <div class="service-card-front">
                      <img
                        src={Service7}
                        alt="sevice1"
                        height="100%"
                        width="100%"
                        className="service-img"
                      />
                    </div>
                    <div class="service-card-back">
                      <h3>Block Paving</h3>
                      <p>
                        Elevate your outdoor aesthetics with block paving. From
                        driveways to patios, our durable and stylish paving
                        solutions enhance curb appeal and create inviting
                        outdoor spaces.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* For mobile view */}
          <div class="container pt-5 mobile">
            <h1 className="text-center">Our Core Services</h1>
            <h5>
              At Chouhan Builders Midlands Ltd., we are committed to delivering
              exceptional construction services for both residential and
              commercial projects. Our experienced team is dedicated to ensuring
              that every project is completed to the highest standards of
              quality, on time, and within budget.
            </h5>
            <div class="row mt-5  mx-2">
              <div class="col-sm-3   px-2 my-2 py-2 s1">
                <div class="service-card">
                  <div class="service-card-inner">
                    <div class="service-card-front">
                      <img
                        src={Service1}
                        alt="sevice1"
                        height="100%"
                        width="100%"
                        className="service-img"
                      />
                    </div>
                    <div class="service-card-back">
                      <h3>Build House:</h3>
                      <p>
                        Crafting your dream home with precision and passion.
                        From foundation to finishing touches, we bring your
                        vision to life with quality craftsmanship and attention
                        to detail.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-3  px-2 my-2 py-2 s1">
                <div class="service-card">
                  <div class="service-card-inner">
                    <div class="service-card-front">
                      <img
                        src={Service2}
                        alt="sevice1"
                        height="100%"
                        width="100%"
                        className="service-img"
                      />
                    </div>
                    <div class="service-card-back">
                      <h3>Electrical: </h3>
                      <p>
                        Expert electrical solutions for your safety and
                        convenience. From wiring to lighting, our skilled
                        electricians ensure reliable performance and compliance
                        with industry standards.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3  px-2 my-2 py-2 s1">
                <div class="service-card">
                  <div class="service-card-inner">
                    <div class="service-card-front">
                      <img
                        src={Service3}
                        alt="sevice1"
                        height="100%"
                        width="100%"
                        className="service-img"
                      />
                    </div>
                    <div class="service-card-back">
                      <h3>Kitchen: </h3>
                      <p>
                        Elevate your culinary space with our kitchen remodeling
                        services. From custom cabinetry to modern fixtures, we
                        transform your kitchen into a stylish and functional hub
                        for cooking and entertaining.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mx-2">
              <div class="col-sm-3   px-2 my-2 py-2 s1">
                <div class="service-card">
                  <div class="service-card-inner">
                    <div class="service-card-front">
                      <img
                        src={Service4}
                        alt="sevice1"
                        height="100%"
                        width="100%"
                        className="service-img"
                      />
                    </div>
                    <div class="service-card-back">
                      <h3> Bathroom: </h3>
                      <p>
                        Indulge in luxury and relaxation with our bathroom
                        renovation expertise. From elegant fixtures to spa-like
                        features, we create a serene oasis tailored to your
                        taste and lifestyle.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-3 px-2 my-2 py-2 s1">
                <div class="service-card">
                  <div class="service-card-inner">
                    <div class="service-card-front">
                      <img
                        src={Service5}
                        alt="sevice1"
                        height="100%"
                        width="100%"
                        className="service-img"
                      />
                    </div>
                    <div class="service-card-back">
                      <h3>Loft & property Conversion:</h3>
                      <p>
                        Unlock the potential of your home with a loft
                        conversion. From extra bedrooms to home offices, we
                        maximize space and functionality with innovative design
                        and skilled construction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-3  px-2 my-2 py-2 s1">
                <div class="service-card">
                  <div class="service-card-inner">
                    <div class="service-card-front">
                      <img
                        src={Service6}
                        alt="sevice1"
                        height="100%"
                        width="100%"
                        className="service-img"
                      />
                    </div>
                    <div class="service-card-back">
                      <h3>Extensions:</h3>
                      <p>
                        Expand your living space with a seamless home extension.
                        From planning to construction, we enhance your home's
                        footprint while preserving its architectural integrity
                        and style.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row  mx-2">
              <div class="col-sm-3   px-2 my-2 py-2 s1">
                <div class="service-card">
                  <div class="service-card-inner">
                    <div class="service-card-front">
                      <img
                        src={Service8}
                        alt="sevice1"
                        height="100%"
                        width="100%"
                        className="service-img"
                      />
                    </div>
                    <div class="service-card-back">
                      <h3> Plumbing and General Building: </h3>
                      <p>
                        Comprehensive building services for all your
                        construction needs. From plumbing installations to
                        full-scale renovations, we deliver quality workmanship
                        and personalized solutions to enhance your home.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-3 px-2 my-2 py-2 s1">
                <div class="service-card">
                  <div class="service-card-inner">
                    <div class="service-card-front">
                      <img
                        src={Service7}
                        alt="sevice1"
                        height="100%"
                        width="100%"
                        className="service-img"
                      />
                    </div>
                    <div class="service-card-back">
                      <h3>Block Paving</h3>
                      <p>
                        Elevate your outdoor aesthetics with block paving. From
                        driveways to patios, our durable and stylish paving
                        solutions enhance curb appeal and create inviting
                        outdoor spaces.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="alt-services-2" class="alt-services section-bg mt-5 p">
          <div class="container" data-aos="fade-up">
            <div class="row justify-content-around gy-4 py-5">
              <div class="col-lg-8 col-xl-6 d-flex flex-column justify-content-center">
                <div
                  class="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <i class="bi bi-easel flex-shrink-0"></i>
                  <div>
                    <h4>
                      <a href="#" class="stretched-link">
                        Custom Home Building:
                      </a>
                    </h4>
                    <p>
                      Work closely with our skilled architects to design a
                      personalized home that embodies your unique style and
                      meets your needs perfectly.
                    </p>
                  </div>
                </div>

                <div
                  class="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <i class="bi bi-patch-check flex-shrink-0"></i>
                  <div>
                    <h4>
                      <a href="#" class="stretched-link">
                        Home Renovations:
                      </a>
                    </h4>
                    <p>
                      We focus on modernizing and upgrading key areas of your
                      home with high-end finishes and innovative designs
                      tailored to your lifestyle.
                    </p>
                  </div>
                </div>

                <div
                  class="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <i class="bi bi-brightness-high flex-shrink-0"></i>
                  <div>
                    <h4>
                      <a href="#" class="stretched-link">
                        Design-Build:
                      </a>
                    </h4>
                    <p>
                      Our combined design and construction teams work together
                      for streamlined communication, faster project completion,
                      and a cohesive vision from start to finish.
                    </p>
                  </div>
                </div>

                <div
                  class="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i class="bi bi-brightness-high flex-shrink-0"></i>
                  <div>
                    <h4>
                      <a href="#" class="stretched-link">
                        Project Management:
                      </a>
                    </h4>
                    <p>
                      We provide comprehensive project management services to
                      keep your construction or renovation project on schedule
                      and within budget. Our meticulous attention to detail and
                      regular updates ensure transparent communication and
                      client satisfaction throughout the entire process.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-xl-5">
                <img
                  src={Image}
                  alt="Residential Image"
                  height="100%"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </section>

        <section id="alt-services" class="alt-services mt-5 pb-5">
          <div class="container" data-aos="fade-up">
            <div class="row justify-content-around gy-4">
              <div class="col-lg-6 col-xl-6 mt-5 web">
                <img
                  src={Image2}
                  class="img-fluid"
                  alt="Commercial Image"
                  height="100%"
                  width="100%"
                />
              </div>

              <div class="col-lg-6 col-xl-6 d-flex flex-column justify-content-center">
                <h3>Commercial Service Features</h3>
                <p>
                  Delivering excellence in every aspect of commercial
                  construction and renovation.
                </p>

                <div
                  class="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <i class="bi bi-easel flex-shrink-0"></i>
                  <div>
                    <h4>
                      <a href="#" class="stretched-link">
                        Office Buildings:
                      </a>
                    </h4>
                    <p>
                      Collaborate with our architects and designers to create
                      office spaces that enhance productivity and reflect your
                      company’s brand identity. Our goal is to design
                      functional, stylish, and innovative work environments.
                    </p>
                  </div>
                </div>

                <div
                  class="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <i class="bi bi-patch-check flex-shrink-0"></i>
                  <div>
                    <h4>
                      <a href="#" class="stretched-link">
                        Retail Spaces:
                      </a>
                    </h4>
                    <p>
                      Develop retail spaces that attract customers and optimize
                      sales. We focus on creating engaging and efficient layouts
                      that cater to the needs of both businesses and shoppers.
                    </p>
                  </div>
                </div>

                <div
                  class="icon-box d-flex position-relative"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <i class="bi bi-brightness-high flex-shrink-0"></i>
                  <div>
                    <h4>
                      <a href="#" class="stretched-link">
                        Industrial Facilities:
                      </a>
                    </h4>
                    <p>
                      Construct industrial facilities such as warehouses that
                      prioritize efficiency and safety. Our designs ensure that
                      your operations run smoothly and effectively.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-xl-6 mt-5 mobile">
                <img
                  src={Image2}
                  class="img-fluid"
                  alt="Commercial Image"
                  height="100%"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Services;
