// // import React from "react";
// // import "../CSS/navbar.css";
// // import Logo from "../Images/logo5.png"
// // import { Link } from "react-router-dom";

// // const Navbar = () => {
// //     return (
// //         <>
// //         <div>

// //         <nav class="navbar navbar-expand-lg ">
// //   <div class="container d-flex" style={{gap:"30%"}}>
// //     <div>

// //    <Link to="/"><img src={Logo} alt="logo" height="50%" width="50%"/></Link>

// //     <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
// //     data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
// //       <span class="navbar-toggler-icon"></span>
// //     </button>
// //     </div>
// //     <div class="collapse navbar-collapse" id="navbarNav">
// //       <ul class="navbar-nav">
// //         <li class="nav-item ">
// //        <Link to="/" className="nav-link active text-light">Home</Link>
// //         </li>
// //         <li class="nav-item">
// //           <Link to="/services" className="nav-link text-light">Services</Link>
// //         </li>
// //         <li class="nav-item">
// //        <Link to="/residencial"  className="nav-link text-light">Residential</Link>
// //         </li>
// //         <li class="nav-item">
// //          <Link to="/commercial"  className="nav-link text-light">Commercial</Link>
// //         </li>
// //         <li class="nav-item">
// //         <Link to="/project"  className="nav-link text-light">Our Projects</Link>
// //         </li>
// //         <li class="nav-item">
// //           <Link to="/about"  className="nav-link text-light">About</Link>
// //         </li>
// //         <li class="nav-item">
// //         <Link to="/contact"  className="nav-link text-light">Contact Us</Link>
// //         </li>

// //       </ul>
// //     </div>

// //   </div>
// // </nav>

// //         </div>
// //         </>
// //     )
// // }

// // export default Navbar;

// // import {
// //   CCollapse,
// //   CContainer,
// //   CNavItem,
// //   CNavLink,
// //   CNavbar,
// //   CNavbarBrand,
// //   CNavbarNav,
// //   CNavbarToggler,
// // } from "@coreui/react";
// // import React, { useState } from "react";
// // import "../CSS/navbar.css";
// // import Logo from "../Images/logo5.png";

// // const Navbar = () => {
// //   const [visible, setVisible] = useState(false);
// //   const navbarItems = [
// //     { item: "Home", route: "/" },
// //     { item: "About Us", route: "/about" },
// //     { item: "Services", route: "/services" },
// //     { item: "Projects", route: "/project" },
// //     { item: "Residential", route: "/residencial" },
// //     { item: "Commercial", route: "/commercial" },
// //     { item: "Contact Us", route: "/contact" },
// //   ];
// //   return (
// //     <>
// //       <CNavbar
// //         expand="lg"
// //         className={`navbar justify-content-between ${
// //           visible ? "navbar-bg" : ""
// //         }`}
// //       >
// //         <CContainer fluid>
// //           <CNavbarBrand href="/">
// //             <img src={Logo} className="company-logo" />
// //           </CNavbarBrand>
// //           <CNavbarToggler onClick={() => setVisible(!visible)} />
// //           <CCollapse className="navbar-collapse" visible={visible}>
// //             <CNavbarNav>
// //               {navbarItems.map((nav) => (
// //                 <CNavItem>
// //                   <CNavLink href={nav.route} className="nav-link-hover" active>
// //                     {nav.item}
// //                   </CNavLink>
// //                 </CNavItem>
// //               ))}
// //             </CNavbarNav>
// //           </CCollapse>
// //         </CContainer>
// //       </CNavbar>
// //     </>
// //   );
// // };

// // export default Navbar;

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import "../CSS/navbar.css";
// import Logo from "../Images/logo5.png";
// import { FaBars } from "react-icons/fa";

// const Navbar = () => {
//   const [click, setClick] = useState(false);

//   const handleClick = () => setClick(!click);
//   const closeMobileMenu = () => setClick(false);

//   return (
//     <section className="head">
//       <div className="container-fluid">
//         <div className="row gx-5">
//           <div className="col-sm-6">
//             <Link to="/" onClick={closeMobileMenu}>
//               <img
//                 src={Logo}
//                 alt="logo"
//                 className="mx-5"
//                 style={{ width: "15%" }}
//               />
//             </Link>
//           </div>
//           <div className="col-sm-6 mt-3">
//             <nav className="flexSB">
//               <div className="menu-icon" onClick={handleClick}>
//                 <FaBars />
//               </div>
//               <ul className={click ? "nav-menu active" : "nav-menu"}>
//                 <li className="nav-item">
//                   <Link to="/" className="nav-links" onClick={closeMobileMenu}>
//                     Home
//                   </Link>
//                 </li>
//                 <li className="nav-item">
//                   <Link
//                     to="/about"
//                     className="nav-links"
//                     onClick={closeMobileMenu}
//                   >
//                     About
//                   </Link>
//                 </li>
//                 <li className="nav-item">
//                   <Link
//                     to="/services"
//                     className="nav-links"
//                     onClick={closeMobileMenu}
//                   >
//                     Services
//                   </Link>
//                 </li>
//                 <li className="nav-item">
//                   <Link
//                     to="/project"
//                     className="nav-links"
//                     onClick={closeMobileMenu}
//                   >
//                     Projects
//                   </Link>
//                 </li>
//                 <li className="nav-item">
//                   <Link
//                     to="/residencial"
//                     className="nav-links"
//                     onClick={closeMobileMenu}
//                   >
//                     Residential
//                   </Link>
//                 </li>
//                 <li className="nav-item">
//                   <Link
//                     to="/commercial"
//                     className="nav-links"
//                     onClick={closeMobileMenu}
//                   >
//                     Commercial
//                   </Link>
//                 </li>
//                 <li className="nav-item">
//                   <Link
//                     to="/contact"
//                     className="nav-links"
//                     onClick={closeMobileMenu}
//                   >
//                     Contact Us
//                   </Link>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Navbar;

import {
  CCollapse,
  CContainer,
  CNavItem,
  CNavLink,
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
  CNavbarToggler,
} from "@coreui/react";
import React, { useState } from "react";
import "../CSS/navbar.css";
import Logo from "../Images/logo5.png";

const Navbar = () => {
  const [visible, setVisible] = useState(false);
  const navbarItems = [
    { item: "Home", route: "/" },
    { item: "About Us", route: "/about" },
    { item: "Services", route: "/services" },
    { item: "Projects", route: "/project" },
    { item: "Residential", route: "/residencial" },
    { item: "Commercial", route: "/commercial" },
    { item: "Contact Us", route: "/contact" },
  ];
  return (
    <>
      <CNavbar
        expand="lg"
        className={`navbar justify-content-between ${
          visible ? "navbar-bg" : ""
        }`}
      >
        <CContainer fluid>
          <CNavbarBrand href="/">
            <img src={Logo} className="company-logo" />
          </CNavbarBrand>
          <CNavbarToggler onClick={() => setVisible(!visible)} />
          <CCollapse
            className={`navbar-collapse flexSB ${visible ? "navbar-bg" : ""}`}
            visible={visible}
          >
            <CNavbarNav>
              {navbarItems.map((nav) => (
                <CNavItem>
                  <CNavLink href={nav.route} className="nav-link-hover" active>
                    {nav.item}
                  </CNavLink>
                </CNavItem>
              ))}
            </CNavbarNav>
          </CCollapse>
        </CContainer>
      </CNavbar>
    </>
  );
};

export default Navbar;
