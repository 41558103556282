import React, { useEffect } from "react";
import bg from "../Images/Privacy bg.jpeg";
import "../CSS/Terms.css";

const PrivacyComponent = () => {
  useEffect(() => {
    localStorage.setItem("currentPage", "Terms & Conditions");
  }, []);

  return (
    <>
      <section>
        <img src={bg} alt="service" className="servicebackground" />

        <div className="d-flex align-items-center breadcrumbs">
          <div
            class="container position-relative d-flex flex-column align-items-center"
            data-aos="fade"
          >
            <h1 className="my-5 service-head text-light">Terms & Conditions</h1>
            {/* <ol>
                            <li><a href="/">Home</a></li>
                            <li className="text-light">Commercial</li>
                        </ol> */}
          </div>
        </div>
      </section>

      <div className="privacy-container mx-5 fs-5 ">
        {/* <h1>Privacy Notice</h1>
        <p className="effective-date">Effective Date: [28-June-2024]</p> */}

        <div className="privacy-section">
          <h2 className="fs-1"> Introduction</h2>
          <p>
            Welcome to Chouhan Builders . These Terms and Conditions ("Terms")
            govern your use of our website located at{" "}
            <a href="https://www.example.com" style={{ color: "white" }}>
              https://chouhanbuilders.co.uk/
            </a>{" "}
            (together or individually "Service") operated by Chouhan Builders.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="fs-1"> Agreement to Terms</h2>
          <p>
            By accessing or using our Service, you agree to be bound by these
            Terms. If you disagree with any part of the terms, then you do not
            have permission to access the Service.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="fs-1"> Accounts</h2>
          <p>
            When you create an account with us, you guarantee that you are above
            the age of 18, and that the information you provide us is accurate,
            complete, and current at all times. Inaccurate, incomplete, or
            obsolete information may result in the immediate termination of your
            account on the Service.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="fs-1"> Intellectual Property</h2>
          <p>
            The Service and its original content, features, and functionality
            are and will remain the exclusive property of Chouhan Builders and
            its licensors. The Service is protected by copyright, trademark, and
            other laws of both the country and foreign countries.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="fs-1"> User Conduct</h2>
          <p>
            You agree not to use the Service for any purpose that is unlawful or
            prohibited by these Terms. You agree not to use the Service in any
            manner that could damage, disable, overburden, or impair the Service
            or interfere with any other party's use and enjoyment of the
            Service.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="fs-1"> Termination</h2>
          <p>
            We may terminate or suspend your account and bar access to the
            Service immediately, without prior notice or liability, under our
            sole discretion, for any reason whatsoever and without limitation,
            including but not limited to a breach of the Terms.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="fs-1"> Limitation of Liability</h2>
          <p>
            In no event shall Chouhan Builders, nor its directors, employees,
            partners, agents, suppliers, or affiliates, be liable for any
            indirect, incidental, special, consequential, or punitive damages,
            including without limitation, loss of profits, data, use, goodwill,
            or other intangible losses, resulting from (i) your use or access of
            the Service or inability to access or use the Service; (ii) any
            conduct or content of any third party on the Service; (iii) any
            content obtained from the Service; and (iv) unauthorized access,
            use, or alteration of your transmissions or content
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="fs-1"> Governing Law</h2>
          <p>
            These Terms shall be governed and construed in accordance with the
            laws of United States, which governing law applies to the agreement
            without regard to its conflict of law provisions.
          </p>
        </div>
        <div className="privacy-section">
          <h2 className="fs-1"> Changes to Service</h2>
          <p>
            We reserve the right to withdraw or amend our Service, and any
            service or material we provide via the Service, in our sole
            discretion without notice. We will not be liable if for any reason
            all or any part of the Service is unavailable at any time or for any
            period.
          </p>
        </div>
        <div className="privacy-section">
          <h2 className="fs-1"> Contact Us</h2>
          <p>
            If you have any questions about these Terms, please contact us at{" "}
            <a href="mailto:[Contact Email] " style={{ color: "white" }}>
              info@chouhanbuilders.co.uk
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyComponent;
