import React, { useEffect } from "react";
import bg from "../Images/Privacy bg.jpeg";
import "../CSS/privacy.css";

const PrivacyComponent = () => {
  useEffect(() => {
    localStorage.setItem("currentPage", "Privacy & Policy");
  }, []);

  return (
    <>
      <section>
        <img src={bg} alt="service" className="servicebackground" />

        <div className="d-flex align-items-center breadcrumbs">
          <div
            class="container position-relative d-flex flex-column align-items-center"
            data-aos="fade"
          >
            <h1 className="my-5 service-head text-light">Privacy & Policy</h1>
            {/* <ol>
                            <li><a href="/">Home</a></li>
                            <li className="text-light">Commercial</li>
                        </ol> */}
          </div>
        </div>
      </section>

      <div className="privacy-container fs-5 mx-5 ">
        {/* <h1>Privacy Notice</h1>
        <p className="effective-date">Effective Date: [28-June-2024]</p> */}

        <div className="privacy-section">
          {/* <h1>Privacy Notice</h1>
          <p className="effective-date">Effective Date: [28-June-2024]</p>
          <br /> */}
          <h2 className="fs-1"> Introduction</h2>
          <p>
            Welcome to Chouhan Builders. We are committed to protecting your
            personal information and your right to privacy. If you have any
            questions or concerns about this privacy notice or our practices
            with regard to your personal information, please contact us at
            <a href="tel:+155895548855" style={{ color: "white" }}>
              {" "}
              07473785314
            </a>
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="fs-1"> Information We Collect</h2>
          <p>
            We collect personal information that you voluntarily provide to us
            when you register on the website, express an interest in obtaining
            information about us or our products and services, when you
            participate in activities on the website, or otherwise contact us.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="fs-1"> How We Use Your Information</h2>
          <p>
            We use personal information collected via our website for a variety
            of business purposes described below. We process your personal
            information for these purposes in reliance on our legitimate
            business interests, in order to enter into or perform a contract
            with you, with your consent, and/or for compliance with our legal
            obligations.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="fs-1"> Sharing Your Information</h2>
          <p>
            We may process or share your data that we hold based on the
            following legal basis:
          </p>
          <ul>
            <li>
              <h4>Consent:</h4>
              <h5>
                We may process your data if you have given us specific consent
                to use your personal information for a specific purpose.
              </h5>
            </li>
            <li>
              <h4>Legitimate Interests:</h4>
              <h5>
                {" "}
                We may process your data when it is reasonably necessary to
                achieve our legitimate business interests.
              </h5>
            </li>
            <li>
              <h4>Performance of a Contract:</h4>
              <h5>
                {" "}
                Where we have entered into a contract with you, we may process
                your personal information to fulfill the terms of our contract.
              </h5>
            </li>
            <li>
              <h4>Legal Obligations:</h4>
              <h5>
                {" "}
                We may disclose your information where we are legally required
                to do so to comply with applicable law, governmental requests, a
                judicial proceeding, court order, or legal process.
              </h5>
            </li>
          </ul>
        </div>

        <div className="privacy-section">
          <h2 className="fs-1"> Cookies and Tracking Technologies</h2>
          <p>
            We may use cookies and similar tracking technologies to access or
            store information. Specific information about how we use such
            technologies and how you can refuse certain cookies is set out in
            our Cookie Notice.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="fs-1"> Data Retention</h2>
          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice unless a
            longer retention period is required or permitted by law.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="fs-1"> Your Privacy Rights</h2>
          <p>
            In some regions (like the EEA and UK), you have rights that allow
            you greater access to and control over your personal information.
            You may review, change, or terminate your account at any time.
          </p>
        </div>

        <div className="privacy-section">
          <h2 className="fs-1"> Contact Us</h2>
          <p>
            If you have questions or comments about this notice, you may email
            us at{" "}
            <a href="mailto:[Contact Email]" style={{ color: "white" }}>
              info@chouhanbuilders.co.uk
            </a>{" "}
            or by post to:
          </p>
          <p>
            Chouhan Builders
            <br />
            Unit 1-3 Salisbury Street, Widnes, England, WA8 6PJ
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyComponent;
