import React, { useEffect, useState } from "react";
import Service from "../Images/project.png";
import Navbar from "../Common/Navbar";
import Project1 from "../Images/tab1.jpg";
import Project2 from "../Images/room1.jpg";
import Project3 from "../Images/room2.jpg";
import Project4 from "../Images/room3.jpg";
import Bathroom1 from "../Images/c8.jpg";
import Bathroom2 from "../Images/c9.jpg";
import Bathroom3 from "../Images/service4.jpg";
import Bathroom4 from "../Images/bathroom4.jpg";
import Home1 from "../Images/home1.jpg";
import Home2 from "../Images/scroll2.jpg";
import Home3 from "../Images/residencial2.jpg";
import Stair1 from "../Images/stair1.jpg";
import Floor1 from "../Images/floor1.jpg";
import Floor2 from "../Images/floor2.jpg";
import Floor3 from "../Images/floor3.jpg";
import Floor4 from "../Images/Floor 4.jpg";
import Kitchen from "../Images/kitchen2.jpg";
import Hotel1 from "../Images/hotel1.jpg";
import Hotel2 from "../Images/hotel2.webp";
import Hotel3 from "../Images/hotel3.jpg";
import School1 from "../Images/school1.webp";
import School2 from "../Images/school2.jpg";
import School3 from "../Images/school3.jpg";
import Corporate1 from "../Images/corporate1.webp";
import Corporate2 from "../Images/corporate2.jpg";
import Industrial1 from "../Images/industrial1.jpg";
import Industrial2 from "../Images/industrial2.jpg";
import Sports from "../Images/sports.jpg";
import Kitchen3 from "../Images/Kitchen 3.jpg";
import Kitchen4 from "../Images/Kitechn 4.jpg";
import Kitchen5 from "../Images/Kitchen 5.jpg";
import Bath5 from "../Images/Bath 5.jpg";
import Bath6 from "../Images/Bath 6.jpg";
import Bath7 from "../Images/Bath 7.jpg";
import Bath8 from "../Images/Bath 8.jpg";
import Bath9 from "../Images/Bath 9.jpg";
import Bath10 from "../Images/Bath 10.jpg";
import Bath11 from "../Images/Bath 11.jpg";
import Bath12 from "../Images/Bath 12.jpg";
import House4 from "../Images/House 4.jpg";
import House5 from "../Images/House 5.jpg";
import House6 from "../Images/House 6.jpg";
import House7 from "../Images/House 7.jpg";
import Room4 from "../Images/Room 4.jpg";
import Room5 from "../Images/Room 5.jpg";
import Room6 from "../Images/Room 6.jpg";
import Room7 from "../Images/Room 7.jpg";
import Room8 from "../Images/Room 8.jpg";
import Room9 from "../Images/Room 9.jpg";
import Room10 from "../Images/Room 10.jpg";
import Stairs2 from "../Images/Stairs 2.jpg";
import Stairs3 from "../Images/Stairs 3.jpg";
import Stairs4 from "../Images/Stairs 4.jpg";
import "../CSS/project.css";

import {
  CNavItem,
  CNavLink,
  CNav,
  CTabContent,
  CTabPane,
  CImage,
} from "@coreui/react";

const Project = () => {
  const [cactiveKey, csetActiveKey] = useState(1);
  const [activeKey, setActiveKey] = useState(1);
  useEffect(() => {
    localStorage.setItem("currentPage", "Projects");
  }, []);
  return (
    <>
      <section>
        <img src={Service} alt="service" className="servicebackground" />

        <div className="d-flex align-items-center breadcrumbs">
          <div
            class="container position-relative d-flex flex-column align-items-center"
            data-aos="fade"
          >
            <h1 className="my-5 service-head text-light">Projects</h1>
            {/* <ol>
                            <li><a href="/">Home</a></li>
                            <li className="text-light">Project</li>
                        </ol> */}
          </div>
        </div>
      </section>

      <section className="bg-dark text-light py-5 ">
        <section>
          <div className="container pt-5">
            <h1 className="text-center">Our Residential Projects</h1>
            <CNav variant="tabs" role="tablist">
              <CNavItem>
                <CNavLink
                  href="#!"
                  active={activeKey === 3}
                  onClick={() => setActiveKey(3)}
                >
                  House
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  href="#!"
                  active={activeKey === 1}
                  onClick={() => setActiveKey(1)}
                >
                  Kitchen
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  href="#!"
                  active={activeKey === 2}
                  onClick={() => setActiveKey(2)}
                >
                  Bathroom
                </CNavLink>
              </CNavItem>

              <CNavItem>
                <CNavLink
                  href="#!"
                  active={activeKey === 4}
                  onClick={() => setActiveKey(4)}
                >
                  Rooms
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  href="#!"
                  active={activeKey === 5}
                  onClick={() => setActiveKey(5)}
                >
                  Stairs
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  href="#!"
                  active={activeKey === 6}
                  onClick={() => setActiveKey(6)}
                >
                  Floor
                </CNavLink>
              </CNavItem>
            </CNav>
          </div>
          <CTabContent>
            <CTabPane
              role="tabpanel"
              aria-labelledby="home-tab"
              visible={activeKey === 1}
            >
              <div className="container py-5">
                <div className="row project">
                  <div className="col-sm-3 mt-2">
                    <CImage fluid src={Project1} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-5 mt-2">
                    <CImage src={Kitchen} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-3 mt-2">
                    <CImage fluid src={Kitchen3} height="100%" width="100%" />
                  </div>
                </div>
              </div>
            </CTabPane>
            <CTabPane
              role="tabpanel"
              aria-labelledby="profile-tab"
              visible={activeKey === 2}
            >
              <div className="container">
                <div className="row project">
                  <div className="col-sm-3 mt-2">
                    <CImage fluid src={Bathroom1} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-3 mt-2">
                    <CImage fluid src={Bathroom2} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-3 mt-2">
                    <CImage fluid src={Bathroom3} height="100%" width="100%" />
                  </div>

                  <div className="col-sm-3 mt-2">
                    <CImage fluid src={Bath10} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-3 mt-2">
                    <CImage fluid src={Bath11} height="100%" width="100%" />
                  </div>

                  {/* <div className="col-sm-3 mt-2">
                    <CImage fluid src={Bath1} height="100%" width="100%" />
                  </div> */}
                </div>
              </div>
            </CTabPane>
            <CTabPane
              role="tabpanel"
              aria-labelledby="contact-tab"
              visible={activeKey === 3}
            >
              <div className="container">
                <div className="row project">
                  <div className="col-sm-4 mt-2">
                    <CImage fluid src={Home1} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-4 mt-2">
                    <CImage fluid src={Home3} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-4 mt-2">
                    <CImage fluid src={Home2} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-4 mt-2">
                    <CImage fluid src={House4} height="100px" width="100%" />
                  </div>
                </div>
              </div>
            </CTabPane>
            <CTabPane
              role="tabpanel"
              aria-labelledby="contact-tab"
              visible={activeKey === 4}
            >
              <div className="container">
                <div className="row project">
                  <div className="col-sm-4 mt-2">
                    <CImage fluid src={Project2} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-4 mt-2">
                    <CImage fluid src={Project3} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-4 mt-2">
                    <CImage fluid src={Project4} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-4 mt-2">
                    <CImage fluid src={Room4} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-4 mt-2">
                    <CImage fluid src={Room5} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-4 mt-2">
                    <CImage fluid src={Room6} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-4 mt-2">
                    <CImage fluid src={Room7} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-4 mt-2">
                    <CImage fluid src={Room8} height="100%" width="100%" />
                  </div>
                </div>
              </div>
            </CTabPane>
            <CTabPane
              role="tabpanel"
              aria-labelledby="contact-tab"
              visible={activeKey === 5}
            >
              <div className="container">
                <div className="row project">
                  <div className="col-sm-3 mt-2">
                    <CImage fluid src={Stair1} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-3 mt-2">
                    <CImage fluid src={Stairs2} height="100%" width="100%" />
                  </div>

                  <div className="col-sm-3 mt-2">
                    <CImage fluid src={Stairs4} height="100%" width="100%" />
                  </div>
                </div>
              </div>
            </CTabPane>
            <CTabPane
              role="tabpanel"
              aria-labelledby="contact-tab"
              visible={activeKey === 6}
            >
              <div className="container">
                <div className="row project">
                  <div className="col-sm-4 mt-2">
                    <CImage fluid src={Floor1} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-4 mt-2">
                    <CImage fluid src={Floor2} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-4 mt-2">
                    <CImage fluid src={Floor3} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-4 mt-2">
                    <CImage fluid src={Floor4} height="100%" width="100%" />
                  </div>
                </div>
              </div>
            </CTabPane>
          </CTabContent>
        </section>
        <section>
          <div className="container mt-5">
            <h1 className="text-center">Our Commercial Projects</h1>
            <CNav variant="tabs" role="tablist">
              <CNavItem>
                <CNavLink
                  href="#!"
                  active={cactiveKey === 1}
                  onClick={() => csetActiveKey(1)}
                >
                  Hotels & Hospitality
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  href="#!"
                  active={cactiveKey === 2}
                  onClick={() => csetActiveKey(2)}
                >
                  Schools & Colleges
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  href="#!"
                  active={cactiveKey === 3}
                  onClick={() => csetActiveKey(3)}
                >
                  Corporate Interior Projects
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  href="#!"
                  active={cactiveKey === 4}
                  onClick={() => csetActiveKey(4)}
                >
                  Industrials & Warehouses
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink
                  href="#!"
                  active={cactiveKey === 5}
                  onClick={() => csetActiveKey(5)}
                >
                  Sports Complex Projects
                </CNavLink>
              </CNavItem>
            </CNav>
          </div>
          <CTabContent>
            <CTabPane
              role="tabpanel"
              aria-labelledby="home-tab"
              visible={cactiveKey === 1}
            >
              <div className="container">
                <div className="row">
                  <div className="col-sm-3 mt-2">
                    <CImage fluid src={Hotel1} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-3 mt-2">
                    <CImage fluid src={Hotel2} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-3 mt-2">
                    <CImage fluid src={Hotel3} height="100%" width="100%" />
                  </div>
                </div>
              </div>
            </CTabPane>
            <CTabPane
              role="tabpanel"
              aria-labelledby="profile-tab"
              visible={cactiveKey === 2}
            >
              <div className="container">
                <div className="row">
                  <div className="col-sm-3 mt-2">
                    <CImage fluid src={School1} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-3 mt-2">
                    <CImage fluid src={School2} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-3 mt-2">
                    <CImage fluid src={School3} height="100%" width="100%" />
                  </div>
                </div>
              </div>
            </CTabPane>
            <CTabPane
              role="tabpanel"
              aria-labelledby="contact-tab"
              visible={cactiveKey === 3}
            >
              <div className="container">
                <div className="row">
                  <div className="col-sm-3 mt-2">
                    <CImage fluid src={Corporate1} height="100%" width="100%" />
                  </div>
                  <div className="col-sm-5 mt-2">
                    <CImage fluid src={Corporate2} height="100%" width="100%" />
                  </div>
                </div>
              </div>
            </CTabPane>
            <CTabPane
              role="tabpanel"
              aria-labelledby="contact-tab"
              visible={cactiveKey === 4}
            >
              <div className="container">
                <div className="row">
                  <div className="col-sm-6 mt-2">
                    <CImage
                      fluid
                      src={Industrial1}
                      height="100%"
                      width="100%"
                    />
                  </div>
                  <div className="col-sm-6 mt-2">
                    <CImage
                      fluid
                      src={Industrial2}
                      height="100%"
                      width="100%"
                    />
                  </div>
                </div>
              </div>
            </CTabPane>

            <CTabPane
              role="tabpanel"
              aria-labelledby="contact-tab"
              visible={cactiveKey === 5}
            >
              <div className="container">
                <div className="row">
                  <div className="col-sm-5 mt-2">
                    <CImage fluid src={Sports} height="100%" width="100%" />
                  </div>
                </div>
              </div>
            </CTabPane>
          </CTabContent>
        </section>
      </section>
    </>
  );
};
export default Project;

// office structure, office outside, office inside,office intirer, office exterior , hotel and hospitalss, school and college, industrial constrtruction,
