import React from "react";
import "../CSS/footer.css";
import Logo from "../Images/logo5.png";
import { FaFacebookF } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
// import { FaWhatsapp } from "react-icons/fa";
import { FloatingWhatsApp } from "react-floating-whatsapp";
// import MapComponent from "./MapComponent";
// import "font-awesome/css/font-awesome.min.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <>
      <footer id="footer" className="footer">
        <div className="footer-content position-relative">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="footer-info">
                  <Link to="/">
                    {" "}
                    <img src={Logo} alt="logo" height="30%" width="30%" />
                  </Link>

                  <p>
                    <br />
                    <strong>Phone:</strong> 07473785314
                    <br />
                    <strong>Email:</strong> info@chouhanbuilders.co.uk
                    <br />
                  </p>
                  <div className="social-links d-flex mt-3">
                    <Link to="/" className="px-2 pt-2">
                      <FaFacebookF />
                    </Link>

                    <Link className="px-2 pt-2">
                      <FaInstagram />
                    </Link>
                    <Link className="px-2 pt-2">
                      <FaLinkedinIn />
                    </Link>
                    {/* <Link className="px-2 pt-2">
                      <FaWhatsapp />
                    </Link> */}
                  </div>
                  <Link className="px-2 pt-2">
                    <a href="https://wa.me/+447473785314">
                      <FloatingWhatsApp />
                    </a>
                  </Link>
                </div>
              </div>
              {/* End footer info column*/}
              <div className="col-lg-2 col-md-3 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about">About us</a>
                  </li>
                  <li>
                    <a href="/services">Services</a>
                  </li>
                  <li>
                    <a href="/project">Our Projects</a>
                  </li>
                  <li>
                    <a href="/residencial">Residential</a>
                  </li>
                  <li>
                    <a href="/commercial">Commercial</a>
                  </li>
                </ul>
              </div>
              {/* End footer links column*/}
              <div className="col-lg-2 col-md-3 footer-links ">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <a href="Services">New Build House</a>
                  </li>
                  <li>
                    <a href="Services">Bathrooms</a>
                  </li>
                  <li>
                    <a href="Services">Loft Conversion</a>
                  </li>
                  <li>
                    <a href="Services">Electrical</a>
                  </li>
                  <li>
                    <a href="Services">Extensions</a>
                  </li>
                  <li>
                    <a href="Services">Kitchens</a>
                  </li>
                </ul>
              </div>
              {/* End footer links column*/}
              <div className="col-lg-2 col-md-3 footer-links">
                <h4>Our Projects</h4>
                <ul>
                  <li>
                    <a href="/residencial">Residential</a>
                  </li>
                  <li>
                    <a href="/commercial">Commercial</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-2 col-md-3 footer-links">
                <h4>Customer policies</h4>
                <ul>
                  <li>
                    <a href="/privacy-policy">Privacy & Policy</a>
                  </li>
                  <li>
                    <a href="/Terms">Terms & Conditions</a>
                  </li>
                </ul>
              </div>
              {/* End footer links column*/}
              {/* <div className="col-lg-2">
                <MapComponent />
              </div> */}
            </div>
          </div>
        </div>
      </footer>

      {/* <div className="nav mobile" role="navigation">
        <ul className="nav-list">
          {footerData.map((dataFooter, index) => (
            <li key={index}>
              <input
                id={"group-" + (index + 1)}
                type="radio"
                name="nav-group"
                checked={selectedItem === "group-" + (index + 1)}
                onClick={() => handleToggle("group-" + (index + 1))}
                hidden
              />
              <label htmlFor={"group-" + (index + 1)}>
                <FontAwesomeIcon icon={faAngleDown} className="arrows-footer" />
                {dataFooter.heading}
              </label>
              <ul className="group-list">
                {dataFooter.colData.map((value, subIndex) => (
                  <li key={subIndex}>
                    {dataFooter.routeLink ? (
                      <Link
                        to={dataFooter.routeLink[subIndex]}
                        onClick={scrollToTop}
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        {value}
                      </Link>
                    ) : (
                      <span>{value}</span>
                    )}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div> */}
    </>
  );
};
export default Footer;
