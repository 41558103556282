import React, { useEffect } from "react";
import Navbar from "../Common/Navbar";
import Service from "../Images/resibg2.jpg";
import Image1 from "../Images/service1.jpg";
import Project1 from "../Images/tab1.jpg";
import Project2 from "../Images/room1.jpg";
import Project3 from "../Images/room2.jpg";
import Project4 from "../Images/room3.jpg";
import Bathroom1 from "../Images/c8.jpg";
import Bathroom2 from "../Images/c9.jpg";
import Bathroom3 from "../Images/service4.jpg";
import Bathroom4 from "../Images/bathroom4.jpg";
import Home1 from "../Images/home1.jpg";
import Home2 from "../Images/scroll2.jpg";
import Home3 from "../Images/residencial2.jpg";
import Stair1 from "../Images/stair1.jpg";
import Floor1 from "../Images/floor1.jpg";
import Floor2 from "../Images/floor2.jpg";
import Floor3 from "../Images/floor3.jpg";
import Image from "../Images/service.jpg";
import Image3 from "../Images/resident.jpg";
import Image4 from "../Images/resident2.jpg";
const Residencial = () => {
  useEffect(() => {
    localStorage.setItem("currentPage", "Residential");
  }, []);
  return (
    <>
      <section>
        <img src={Service} alt="service" className="servicebackground" />

        <div className="d-flex align-items-center breadcrumbs">
          <div class="container position-relative d-flex flex-column align-items-center">
            <h1
              className="my-5 text-light service-head"
              style={{ fontSize: "85px" }}
            >
              Residential
            </h1>
            {/* <ol>
                            <li><a href="/">Home</a></li>
                            <li className="text-light">Residencial</li>
                        </ol> */}
          </div>
        </div>
      </section>

      <section className=" bg-dark text-light py-5">
        <section>
          <div className="container mt-5">
            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-6">
                    <img
                      src={Project2}
                      alt="image"
                      height="260px"
                      width="100%"
                    />
                  </div>
                  <div className="col-sm-6"></div>
                  <div className="row">
                    <div className="col-sm-6"></div>
                    <div className="col-sm-6 web">
                      <img
                        src={Home1}
                        alt="image"
                        height="260px"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className="col-sm-6 mb-4 mobile">
                  <img src={Home1} alt="image" height="260px" width="100%" />
                </div>
              </div>
              <div className="col-sm-6">
                <h3>Comprehensive Residential Construction Services</h3>
                <h5 className="mt-3">
                  <span className="text-gold">
                    Customized Design Solutions:
                  </span>{" "}
                  Tailoring designs to suit individual preferences, lifestyle
                  needs, and architectural styles, ensuring each home reflects
                  the unique vision of the homeowner.
                </h5>
                <h5 className="mt-3">
                  <span className="text-gold">Transparent Communication:</span>{" "}
                  Maintaining open lines of communication throughout the
                  construction process, providing regular updates, addressing
                  concerns promptly, and ensuring clients are informed and
                  involved at every stage.
                </h5>
                <h5 className="mt-3">
                  <span className="text-gold">
                    Sustainable Building Practices:
                  </span>{" "}
                  Incorporating eco-friendly materials, energy-efficient
                  systems, and sustainable construction practices to minimize
                  environmental impact and enhance long-term cost savings for
                  homeowners.
                </h5>
                <h5 className="mt-3">
                  <span className="text-gold">Collaborative Approach:</span>{" "}
                  Working closely with clients, architects, engineers, and
                  subcontractors to foster collaboration, streamline
                  decision-making, and ensure seamless coordination from concept
                  to completion.
                </h5>
                <h5 className="mt-3">
                  <span className="text-gold">Quality Craftsmanship:</span>{" "}
                  Upholding the highest standards of craftsmanship, attention to
                  detail, and construction excellence, delivering homes of
                  enduring beauty, functionality, and value that exceed client
                  expectations.
                </h5>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container  my-5 mt-4">
            <div className="col-sm-6 mobile">
              <img src={Image} alt="image" height="90%" width="100%" />
            </div>
            <div className="row my-5 py-5">
              <div className="col-sm-6">
                <h4>
                  Comprehensive Residential Construction and Renovation
                  Solutions
                </h4>
                <h5 className="mt-3">
                  <span className="text-gold">
                    Full-Spectrum Construction Services:
                  </span>
                  From initial groundwork and structural framework to meticulous
                  interior and exterior finishes, we manage every aspect of your
                  construction project with precision and professionalism.
                </h5>
                <h5 className="mt-3">
                  <span className="text-gold">Specialized Expertise:</span>Our
                  team of skilled professionals includes electrical, plumbing,
                  HVAC, and smart home integration specialists, ensuring
                  seamless integration of all essential systems for your home.
                </h5>
                <h5 className="mt-3">
                  <span className="text-gold">
                    Renovation and Remodeling Mastery:
                  </span>
                  Whether it's rejuvenating kitchens and bathrooms, optimizing
                  basement spaces, or expanding your living areas with room
                  additions, we bring creativity and craftsmanship to every
                  renovation project.
                </h5>
                <h5 className="mt-3">
                  <span className="text-gold">
                    Tailored Solutions for Every Need:{" "}
                  </span>
                  Whether you're building a new home from the ground up or
                  revitalizing your existing space, our comprehensive services
                  cater to your unique vision, requirements, and lifestyle
                  preferences.
                </h5>
              </div>
              <div className="col-sm-6 web">
                <img src={Image} alt="image" height="90%" width="100%" />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container ">
            <div className="row">
              <div className="col-sm-6 mt-3">
                <img src={Image3} alt="image" height="90%" width="100%" />
              </div>
              <div className="col-sm-6">
                <h4>
                  Complete Home Care: Sustainable Living, Outdoor Bliss, and
                  maintenance Mastery
                </h4>
                <h5 className="mt-3">
                  <span className="text-gold"> Energy-Efficient Upgrades:</span>{" "}
                  Incorporating insulation, solar panels, and green building
                  materials to optimize energy efficiency, reduce utility costs,
                  and minimize environmental impact.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">Outdoor Oasis Creation:</span>{" "}
                  From landscaping design and construction of decks, patios, and
                  fencing to crafting functional driveways and walkways, we
                  transform outdoor spaces into inviting retreats that
                  complement your lifestyle.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Comprehensive Maintenance Services:
                  </span>{" "}
                  Offering routine maintenance to keep your home systems in top
                  condition, prompt response to emergency repairs for plumbing,
                  electrical, and roofing issues, and seasonal services to
                  prepare your home for the changing seasons, ensuring comfort
                  and longevity for your property.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Integrated Sustainability Practices:
                  </span>{" "}
                  Our approach to landscaping and outdoor living includes the
                  use of sustainable practices and materials, such as native
                  plantings, rainwater harvesting systems, and permeable paving,
                  to create beautiful landscapes that are also environmentally
                  friendly.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Proactive Care for Long-Term Value:
                  </span>{" "}
                  By combining energy-efficient solutions, thoughtful outdoor
                  design, and proactive maintenance, we help homeowners enhance
                  the value, comfort, and sustainability of their homes for
                  years to come.
                </h5>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="col-sm-6 mt-3 mobile">
              <img src={Image4} alt="image" height="75%" width="100%" />
            </div>
            <div className="row">
              <div className="col-sm-6  mt-5">
                <h4>Client-Centric Commitment: Your Vision, Our Expertise</h4>
                <h5 className="mt-3">
                  <span className="text-gold">Customized Creations:</span> From
                  exquisite millwork to personalized interior design, we bring
                  your unique vision to life, ensuring every detail reflects
                  your taste and style.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">Specialized Features:</span>{" "}
                  Elevate your living experience with bespoke additions such as
                  home theatres, wine cellars, or customized home offices,
                  tailored to your lifestyle and preferences.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">Enduring Quality Assurance:</span>{" "}
                  Our commitment extends beyond construction completion, with
                  thorough post-construction inspections to ensure every aspect
                  of your home meets our exacting standards.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">Peace of Mind Warranties:</span>{" "}
                  Rest easy knowing your investment is protected with
                  comprehensive warranties covering both workmanship and
                  materials, providing you with long-term confidence in your
                  home.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">Dedicated Customer Care:</span>{" "}
                  Our support doesn't end when the project does. We're here to
                  provide ongoing assistance and support, ensuring your home
                  continues to meet your needs and expectations long into the
                  future.
                </h5>
              </div>
              <div className="col-sm-6 mt-5 web">
                <img src={Image4} alt="image" height="75%" width="100%" />
              </div>
            </div>
          </div>
        </section>
        <section></section>
      </section>
    </>
  );
};
export default Residencial;
