import React, { useEffect } from "react";
import Service from "../Images/Commercial bg2.jpg";
import Navbar from "../Common/Navbar";
import Image1 from "../Images/comm2.jpg";
import Image2 from "../Images/commercial content1.jpg";
import Image3 from "../Images/commercial1.jpg";
import Image4 from "../Images/commercial5.jpg";
import Image5 from "../Images/Commercial4.jpg";

const Commercial = () => {
  useEffect(() => {
    localStorage.setItem("currentPage", "Commercial");
  }, []);
  return (
    <>
      <section>
        <img src={Service} alt="service" className="servicebackground" />

        <div className="d-flex align-items-center breadcrumbs">
          <div
            class="container position-relative d-flex flex-column align-items-center"
            data-aos="fade"
          >
            <h1 className="my-5 service-head text-light">Commercial</h1>
            {/* <ol>
                            <li><a href="/">Home</a></li>
                            <li className="text-light">Commercial</li>
                        </ol> */}
          </div>
        </div>
      </section>

      <section className=" bg-dark text-light py-5 ">
        <section>
          <div className="container  ">
            <div className="row py-5">
              <div className="col-sm-6 mt-4">
                <img src={Image3} alt="image" height="99%" width="100%" />
              </div>
              <div className="col-sm-6 pt-4 ">
                <h4>
                  Comprehensive Commercial Construction Solutions: From Concept
                  to Completion
                </h4>
                <h5 className="mt-3">
                  <span className="text-gold">
                    Strategic Planning and Site Selection:
                  </span>{" "}
                  We start by understanding your project goals and conducting
                  thorough evaluations to ensure optimal site selection and
                  feasibility studies.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Expert Design and Architectural Excellence:
                  </span>{" "}
                  Collaborating closely with top architects, we create
                  innovative and functional designs that meet your commercial
                  needs while adhering to aesthetic standards.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Meticulous Project Management:
                  </span>{" "}
                  From detailed planning and scheduling to rigorous budget
                  management and risk assessment, we ensure that your project
                  stays on track, on time, and within budget.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Quality Craftsmanship and Compliance:
                  </span>{" "}
                  Our skilled team executes every aspect of construction with
                  precision and care, adhering to strict quality standards and
                  regulatory requirements to deliver superior results.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    End-to-End Construction Expertise:
                  </span>{" "}
                  From site preparation and structural construction to exterior
                  finishes and interior build-outs, we provide comprehensive
                  construction services tailored to your project's unique
                  requirements.
                </h5>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-5">
            <div className="col-sm-6 mobile">
              <img src={Image2} alt="image" height="100%" width="100%" />
            </div>
            <div className="row py-5">
              <div className="col-sm-6">
                <h4>
                  Diverse Construction Solutions: Specialized Services and
                  Renovation Expertise
                </h4>
                <h5 className="mt-3">
                  <span className="text-gold">
                    Speciality System Installation:
                  </span>{" "}
                  From electrical and plumbing to HVAC and fire protection, we
                  offer expertise in installing essential systems to ensure the
                  safety, comfort, and functionality of your commercial space.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Comprehensive Renovation Services:{" "}
                  </span>
                  Whether it's office spaces, retail environments, industrial
                  facilities, or tenant improvements, we specialize in
                  revitalizing and transforming spaces to meet evolving needs
                  and standards.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Tailored Solutions for Every Sector:
                  </span>{" "}
                  Our renovation and remodeling services are tailored to the
                  unique requirements of each sector, ensuring that your
                  commercial space reflects your brand identity, enhances
                  productivity, and maximizes efficiency.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Efficiency and Safety Upgrades:{" "}
                  </span>
                  We focus on enhancing efficiency and safety in industrial
                  facilities through strategic upgrades and modernization
                  initiatives, helping you stay competitive and compliant in
                  today's dynamic business landscape.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">Customized Tenant Spaces:</span>{" "}
                  With our tenant improvement expertise, we customize commercial
                  spaces to meet the specific needs and preferences of new
                  tenants, providing turnkey solutions for seamless transitions
                  and tenant satisfaction.
                </h5>
              </div>
              <div className="col-sm-6 web">
                <img src={Image2} alt="image" height="100%" width="100%" />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-5">
            <div className="row py-2">
              <div className="col-sm-6 mb-0">
                <img src={Image1} alt="image" height="92%" width="100%" />
              </div>
              <div className="col-sm-6">
                <h4>
                  Building Tomorrow: Sustainable, Specialized, and
                  Infrastructure Solutions
                </h4>
                <h5 className="mt-3">
                  <span className="text-gold">Sustainability at the Core:</span>{" "}
                  Our commitment to sustainable building practices is evident in
                  every project, from incorporating green design principles to
                  implementing energy-efficient solutions and guiding clients
                  through LEED certification processes.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Expertise in Specialized Projects:
                  </span>{" "}
                  With extensive experience in healthcare, education,
                  hospitality, and retail sectors, we deliver specialized
                  construction projects tailored to the unique needs and
                  standards of each industry, ensuring functional, innovative,
                  and compliant facilities.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Driving Infrastructure Development:
                  </span>{" "}
                  From roads and highways to utilities installation and public
                  works projects, we play a crucial role in infrastructure
                  development, contributing to the growth, connectivity, and
                  resilience of communities through robust and reliable
                  infrastructure solutions.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Quality Assurance and Compliance:
                  </span>{" "}
                  Our rigorous quality assurance processes and adherence to
                  industry regulations and standards ensure that every project
                  meets or exceeds expectations, delivering safe, durable, and
                  high-quality infrastructure that enhances the lives of
                  residents and users.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Collaborative Approach to Success:
                  </span>{" "}
                  We partner closely with clients, stakeholders, and communities
                  to deliver successful outcomes, leveraging our expertise,
                  innovation, and commitment to excellence to create enduring
                  infrastructure that supports sustainable growth and
                  prosperity.
                </h5>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-5">
            <div className="col-sm-6 mobile">
              <img src={Image4} alt="image" height="100%" width="100%" />
            </div>
            <div className="row ">
              <div className="col-sm-6">
                <h4>Complete Construction Care: From Build to Beyond</h4>
                <h5 className="mt-3">
                  <span className="text-gold">
                    Meticulous Post-Construction Services:
                  </span>{" "}
                  We ensure a seamless transition from construction to occupancy
                  through comprehensive final inspections, meticulous
                  commissioning, and seamless handover processes.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Long-Term Assurance and Support:{" "}
                  </span>
                  Our commitment to your project doesn't end at completion. We
                  provide robust warranties, ongoing maintenance services, and
                  dedicated facility management to ensure the continued
                  performance and longevity of your property.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Tailored Customization and Personalization:
                  </span>{" "}
                  Elevate your space with bespoke elements crafted through
                  custom millwork, expert carpentry, and unique architectural
                  features that reflect your style and vision.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Professional Interior Design Services:
                  </span>{" "}
                  Our team of interior designers collaborates closely with you
                  to create captivating interiors that harmonize with the
                  architectural design, enhancing both functionality and
                  aesthetics.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Holistic Construction Solutions:
                  </span>{" "}
                  From start to finish, we offer comprehensive construction
                  solutions that prioritize sustainability, specialized
                  projects, infrastructure development, and personalized
                  attention to detail, ensuring your vision is brought to life
                  with excellence at every step.
                </h5>
              </div>
              <div className="col-sm-6 web">
                <img src={Image4} alt="image" height="100%" width="100%" />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mt-5">
            <div className="row  py-5">
              <div className="col-sm-6">
                <img src={Image5} alt="image" height="100%" width="100%" />
              </div>
              <div className="col-sm-6">
                <h4>
                  Tomorrow's Tech & Emergency Solutions: Integration &
                  Restoration
                </h4>
                <h5 className="mt-3">
                  <span className="text-gold"> Smart Building Solutions:</span>{" "}
                  We harness cutting-edge technology to create intelligent
                  buildings, integrating smart systems for automation,
                  efficiency, and an enhanced user experience.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">Robust IT Infrastructure:</span>{" "}
                  From data networks to communication systems, we build reliable
                  IT infrastructure to support your business operations and
                  connectivity needs.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Comprehensive Security Measures:
                  </span>{" "}
                  Our expertise extends to the implementation of advanced
                  security systems, including surveillance, access control, and
                  intrusion detection, to safeguard your property and assets.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">
                    Emergency Response and Recovery:
                  </span>{" "}
                  In times of crisis, our team is ready to provide swift and
                  effective disaster recovery services, restoring commercial
                  properties to their pre-loss condition and ensuring minimal
                  disruption to your operations.
                </h5>

                <h5 className="mt-3">
                  <span className="text-gold">Restoration Excellence:</span>{" "}
                  With a focus on quality craftsmanship and attention to detail,
                  we handle all aspects of restoration and repair, delivering
                  superior results that exceed expectations and restore peace of
                  mind.
                </h5>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};
export default Commercial;
