import React, { useEffect, useState } from "react";
// import Navbar from "../Common/Navbar";
import "../CSS/home.css";
import Tab1 from "../Images/tab1.jpg";
import Tab2 from "../Images/tab2.jpg";
import Tab3 from "../Images/tab3.jpg";
import Tab4 from "../Images/tab4.jpg";
import Tab5 from "../Images/tab5.jpg";
import Tab6 from "../Images/tab6.jpg";
import ScrollView from "../Views/ScrollView";
// import Image from "../Images/Imgge1.jpg";
import Service from "../Images/service.jpg";
import HorizontalScroll from "../Views/HorizontalScroll";
// import Background from "../Images/back-image.jpg";
import Residencial from "../Images/residencial.jpg";
import Residencial2 from "../Images/residencial2.jpg";
import Residencial3 from "../Images/residencial3.jpg";
import Commercial from "../Images/commercial.jpg";
import Commercial2 from "../Images/Commercial4.jpg";
import Commercial3 from "../Images/green.jpg";
// import CarouselImg1 from "../Images/Commercial4.jpg";
// import Video from "../Images/background3.mp4";
import About from "../Images/about.jpg";
// import Project1 from "../Images/tab1.jpg";
import Project2 from "../Images/room1.jpg";
import Project3 from "../Images/room2.jpg";
import Project4 from "../Images/room3.jpg";
import Bathroom1 from "../Images/c8.jpg";
import Bathroom2 from "../Images/c9.jpg";
import Bathroom3 from "../Images/service4.jpg";
import Bathroom4 from "../Images/bathroom4.jpg";
import Home1 from "../Images/home1.jpg";
import Home2 from "../Images/scroll2.jpg";
import Home3 from "../Images/residencial2.jpg";
import Stair1 from "../Images/stair1.jpg";
import Floor1 from "../Images/floor1.jpg";
import Floor2 from "../Images/floor2.jpg";
import Floor3 from "../Images/floor3.jpg";
import Floor4 from "../Images/Floor 4.jpg";
import Kitchen from "../Images/kitchen2.jpg";
import Hotel1 from "../Images/hotel1.jpg";
import Hotel2 from "../Images/hotel2.webp";
import Hotel3 from "../Images/hotel3.jpg";
import School1 from "../Images/school1.webp";
import School2 from "../Images/school2.jpg";
import School3 from "../Images/school3.jpg";
import Corporate1 from "../Images/corporate1.webp";
import Corporate2 from "../Images/corporate2.jpg";
import Industrial1 from "../Images/industrial1.jpg";
import Industrial2 from "../Images/industrial2.jpg";
import Sports from "../Images/sports.jpg";
import Kitchen3 from "../Images/Kitchen 3.jpg";
import Kitchen4 from "../Images/Kitechn 4.jpg";
import Kitchen5 from "../Images/Kitchen 5.jpg";
import Bath5 from "../Images/Bath 5.jpg";
import Bath6 from "../Images/Bath 6.jpg";
import Bath7 from "../Images/Bath 7.jpg";
import Bath8 from "../Images/Bath 8.jpg";
import Bath9 from "../Images/Bath 9.jpg";
import Bath10 from "../Images/Bath 10.jpg";
import Bath11 from "../Images/Bath 11.jpg";
import Bath12 from "../Images/Bath 12.jpg";
import House4 from "../Images/House 4.jpg";
import House5 from "../Images/House 5.jpg";
import House6 from "../Images/House 6.jpg";
import House7 from "../Images/House 7.jpg";
import Room4 from "../Images/Room 4.jpg";
import Room5 from "../Images/Room 5.jpg";
import Room6 from "../Images/Room 6.jpg";
import Room7 from "../Images/Room 7.jpg";
import Room8 from "../Images/Room 8.jpg";
import Room9 from "../Images/Room 9.jpg";
import Room10 from "../Images/Room 10.jpg";
import Stairs2 from "../Images/Stairs 2.jpg";
import Stairs3 from "../Images/Stairs 3.jpg";
import Stairs4 from "../Images/Stairs 4.jpg";
import Com1 from "../Images/House 4.jpg";
import com11 from "../Images/com11.jpg";
import com22 from "../Images/rrrr.png";
import {
  CNavItem,
  CNavLink,
  CNav,
  CTabContent,
  CTabPane,
  // CImage,
} from "@coreui/react";
import Carousel from "../Common/Carousel";

const Home = () => {
  const [hactiveKey, hsetActiveKey] = useState(1);
  useEffect(() => {
    localStorage.setItem("currentPage", "Home");
  }, []);
  const carouselImages = [
    Project2,
    Project3,
    Project4,
    Bathroom1,
    Bathroom2,
    Bathroom3,
    Bathroom4,
    Home1,
    Home2,
    Home3,
    Stair1,
    Floor1,
    Floor2,
    Floor3,
    Floor4,
    Kitchen,
    Hotel1,
    Hotel2,
    Hotel3,
    School1,
    School2,
    School3,
    Corporate1,
    Corporate2,
    Industrial1,
    Industrial2,
    Sports,
    Kitchen3,
    Kitchen4,
    Kitchen5,
    Bath5,
    Bath6,
    Bath7,
    Bath8,
    Bath9,
    Bath10,
    Bath11,
    Bath12,
    House4,
    House5,
    House6,
    House7,
    Room4,
    Room5,
    Room6,
    Room7,
    Room8,
    Room9,
    Room10,
    Stairs2,
    Stairs3,
    Stairs4,
  ];
  return (
    <>
      <div>
        {/* <img src={Background} className='background-image'/> */}
        {/* <video autoPlay loop muted className="home-bckground">
          <source src={Video} type="video/mp4" />
        </video> */}
        <Carousel images={carouselImages} />
        {/* <div
          id="carouselExampleControls"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src={CarouselImg1} class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item">
              <img src={Commercial} class="d-block w-100" alt="..." />
            </div>
            <div class="carousel-item">
              <img src={Commercial2} class="d-block w-100" alt="..." />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>{" "} */}
        {/* --------------------------Home page------------------------------------- */}
        {/* ----------------------------------Top-------------------------------------------- */}
        <section className="home-main pt-3">
          <h1 className="text-center mt-3 w-fit-content m-auto  mt-5 text-light font-jersey home-text-heading">
            Find Real Space and Rebuild{" "}
          </h1>
          <h1 className="text-center w-fit-content m-auto mt-1 text-light font-jersey home-text-heading">
            Your Dream Space
          </h1>
          <p className="text-center mb-0 mt-3 text-light w-fit-content m-auto font-jersey home-text ">
            We are a building construction services provider dedicated to
            <br />
            turning your dream of owning the perfect residence into reality.
          </p>
          <p className="w-fit-content m-auto text-center font-jersey home-text text-light">
            discuss your dream space
          </p>
          <br />

          {/* <div>
            <div className="home-tabs">
              <div
                className={`tab ${activeTab === 1 ? 'active' : ''}`}
                onClick={() => handleTabClick(1)}
              >
                Residential
              </div>
              <div
                className={`tab ${activeTab === 2 ? 'active' : ''}`}
                onClick={() => handleTabClick(2)}
              >
                Commercial
              </div>
            </div>
            <div className="home-tab-content">
              {activeTab === 1 && (
                <div className='row'>
                  <div class="card1 tab-image1 col-sm-4 mt-1">
                    <img src={Tab1} alt='image' height="100%" width="100%" className='tab-image' />
                  </div>
                  <div class="card1 tab-image2 col-sm-4 mt-1">
                    <img src={Tab2} alt='image' height="100%" width="100%" className='tab-image' />
                  </div>
                  <div class="card1 tab-image3 col-sm-4 mt-1">
                    <img src={Tab3} alt='image' height="100%" width="100%" className='tab-image' />
                  </div>
                </div>
              )}
              {activeTab === 2 && (
                <div>
                  <div className='row'>
                    <div class="card1 tab-image4 col-sm-4 mt-1">
                      <img src={Tab4} alt='image' height="100%" width="100%" className='tab-image' />
                    </div>
                    <div class="card1 tab-image5 col-sm-4 mt-1">
                      <img src={Tab5} alt='image' height="100%" width="100%" className='tab-image' />
                    </div>
                    <div class="card1 tab-image6 col-sm-4 mt-1">
                      <img src={Tab6} alt='image' height="100%" width="100%" className='tab-image' />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div> */}
          <section>
            <div className="container flex flex-column justify-content-center align-items-center">
              {/* <h1 className="text-center">Our Residential Projects</h1> */}
              <h1 className="text-center m-auto text-light font-weight-bold extra-bold home-text ls-14 w-fit-content">
                Our Residential Projects
              </h1>
              <CNav className="w-fit-content m-auto gap-2 mt-4" role="tablist">
                <CNavItem>
                  <CNavLink
                    href="#!"
                    active={hactiveKey === 1}
                    onClick={() => hsetActiveKey(1)}
                    className="tab"
                  >
                    Residential
                  </CNavLink>
                </CNavItem>
                <CNavItem>
                  <CNavLink
                    href="#!"
                    active={hactiveKey === 2}
                    onClick={() => hsetActiveKey(2)}
                    className="tab"
                  >
                    Commercial
                  </CNavLink>
                </CNavItem>
              </CNav>
            </div>
            <CTabContent>
              <CTabPane
                role="tabpanel"
                aria-labelledby="home-tab"
                visible={hactiveKey === 1}
              >
                <div className="container">
                  <div className="d-flex w-fit-content gap-2 justify-content-center mt-3">
                    <div class="card1 tab-image1 col-sm-4 mt-1">
                      <img
                        src={Tab1}
                        // alt="image"
                        height="100%"
                        width="100%"
                        className="tab-image"
                      />
                    </div>
                    <div class="card1 tab-image2 col-sm-4 mt-1">
                      <img
                        src={Stairs3}
                        // alt="image"
                        height="100%"
                        width="100%"
                        className="tab-image"
                      />
                    </div>
                    <div class="card1 tab-image3 col-sm-4 mt-1">
                      <img
                        src={Bath9}
                        // alt="image"
                        height="100%"
                        width="100%"
                        className="tab-image"
                      />
                    </div>
                  </div>
                </div>
              </CTabPane>
              <CTabPane
                role="tabpanel"
                aria-labelledby="profile-tab"
                visible={hactiveKey === 2}
              >
                <div className="container w-fit-content">
                  <div className="d-flex w-fit-content gap-2 justify-content-center mt-3">
                    <div class="card1 tab-image4 col-sm-4 mt-1">
                      <img
                        src={Com1}
                        // alt="image"
                        height="100%"
                        width="100%"
                        className="tab-image"
                      />
                    </div>
                    <div class="card1 tab-image5 col-sm-4 mt-1 ">
                      <img
                        src={com22}
                        // alt="image"
                        height="350px"
                        width="100%"
                        className="tab-image"
                      />
                    </div>
                    <div class="card1 tab-image6 col-sm-4 mt-1">
                      <img
                        src={Tab6}
                        // alt="image"
                        height="100%"
                        width="100%"
                        className="tab-image"
                      />
                    </div>
                  </div>
                </div>
              </CTabPane>
            </CTabContent>
          </section>
        </section>
        {/* ----------------------------------------------Top Ended---------------------------------------- */}
        {/* -------------------------------------------------Dark Section--------------------------------------- */}
        <div className="bg-dark text-light dark-main px-5 py-3">
          {/* ----------------------------------------------Latest Project-------------------------------------- */}

          <section className="my-5">
            <div className="container">
              <div className="row">
                <h1 className="pt-4 mx-3 text-center">Ongoing Projects</h1>
              </div>
              <ScrollView />
              <hr className=" my-5" />
            </div>
          </section>

          {/* ----------------------------------------------Latest Project Ended------------------------------------- */}

          {/* --------------------------------------------About Us----------------------------------------- */}

          <section className="p">
            <div className="continer about-us-home ">
              <h1 className="mt-4 text-center p">About Us</h1>
              <br />
              <div className="row">
                <div className="col-sm-6">
                  <img src={About} height="100%" width="100%" />
                </div>
                <div className="col-sm-6 mt-2 p ">
                  <p className="fs-3">Chouhan Builders Midlands</p>
                  <p>
                    {" "}
                    We are a West Midlands-based building contractor committed
                    to delivering excellence in every project we undertake. With
                    a forward-thinking approach, we specialize in residential,
                    commercial, and sustainable construction.
                  </p>
                  <p>
                    Our team of experienced professionals provides high-quality
                    craftsmanship, innovative solutions, and exceptional
                    customer service. We prioritize integrity, quality, and
                    sustainability in all our work, ensuring that each project
                    not only meets but exceeds our clients' expectations. Trust
                    us to transform your vision into reality with unparalleled
                    expertise and dedication.
                  </p>
                  <p>
                    Chouhan Builders have steadily built up a reliable and
                    excellent reputation through word of mouth, throughout
                    Walsall and the neighbouring areas.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <hr className="mx-5" />
          {/* --------------------------------------------------About Us ended----------------------------------- */}

          {/* -------------------------------------------------------Services----------------------------------------- */}
          <section className="p">
            <div className="container my-5">
              <div className="row">
                <h1 className="text-center mb-4">Services</h1>
                <div className="col-sm-6">
                  <img
                    src={Service}
                    // alt="image"
                    height="100%"
                    width="100%"
                    className="home-service"
                  />
                </div>
                <div className="col-sm-6 p">
                  <h5 className="mt-3">
                    We offer the following building services and more:
                  </h5>
                  <br />
                  <div className="d-flex gap-5">
                    <ul className="fs-5">
                      <li>Groundworks</li>
                      <li>Retaining Walls</li>
                      <li>Extensions</li>
                      <li className="mobile">Loft & Property Conversions</li>
                      <li className="mobile">New Builds</li>
                      <li className="mobile">Roofing</li>
                    </ul>
                    <ul className="fs-5 web">
                      <li>Loft & Property Conversions</li>
                      <li>New Builds</li>
                      <li>Roofing</li>
                    </ul>
                  </div>
                  <h4 className="mt-3 ">
                    Delivering exceptional services, every time.
                  </h4>
                  <p>
                    At Chouhan Builders Midlands, we offer a comprehensive range
                    of construction services tailored to both residential and
                    commercial clients.{" "}
                  </p>
                  <p>
                    {" "}
                    For residential projects, we specialize in building
                    beautiful, functional homes, and renovating and converting
                    existing properties to enhance comfort and value.
                  </p>
                  <p>
                    In the commercial sector, we develop efficient,
                    aesthetically pleasing spaces that support business
                    operations and growth.
                  </p>
                  <p>
                    Our team excels in delivering projects that meet the highest
                    standards of quality and safety, utilizing innovative
                    techniques and sustainable practices to ensure lasting
                    results.
                  </p>
                  <p>
                    Whether it's a bespoke home, new block of flats, dynamic
                    office, or a retail space, we bring your vision to life with
                    precision and care.
                  </p>
                </div>
              </div>
            </div>
            <hr className="mx-5 " />
            <section>
              <div className="container my-5 test">
                <h1 className="text-center">Testimonial</h1>
                <HorizontalScroll />
              </div>
            </section>
          </section>
          <hr className="mx-5" />
          {/* --------------------------------------------------Services Ended------------------------------------------- */}

          {/* ------------------------------------------------Residenciaal==----------------------------------------------------- */}

          <section className="mt-5">
            <div className="container my-5">
              <h1 className="my-5 text-center">Residential</h1>
              <div className="d-flex flex-wrap mt-5">
                <div className="image mobile ">
                  <img
                    src={Residencial}
                    alt="residencial-image"
                    height="95%"
                    width="75%"
                  />

                  {/* <div id="DIV2" className='mt-5 mb-0'>DIV2</div> */}
                </div>
                <div className="resi-image web">
                  <img
                    src={Residencial}
                    alt="residencial-image"
                    height="95%"
                    width="75%"
                  />
                </div>

                <div className="para ">
                  <h3>Crafting Homes with Care and Excellence</h3>
                  <p>
                    At Chouhan Builders Midlands, we understand that your home
                    is more than just a building; it's a reflection of your
                    lifestyle and a sanctuary for your family. Our residential
                    construction services are designed to create beautiful,
                    functional homes that meet your unique needs and
                    preferences.
                  </p>
                  <p>
                    {" "}
                    From new buildings to extensive renovations, we use
                    high-quality materials and the latest construction
                    techniques to ensure durability and aesthetic appeal. Our
                    experienced team works closely with you throughout the
                    entire process, from initial design to final touches,
                    ensuring every detail is perfect. Trust us to turn your
                    dream home into a reality with unparalleled craftsmanship
                    and dedication.
                  </p>
                </div>
              </div>

              <div className="d-flex flex-wrap mt-5">
                <div className="image mobile ">
                  <img
                    src={Residencial2}
                    alt="image"
                    height="95%"
                    width="75%"
                  />
                </div>
                <div className="para ">
                  <h3>Building Your Dream Home with Precision and Passion</h3>
                  <p>
                    At Chouhan Builders Midlands, we specialize in creating
                    exceptional residential spaces that truly feel like home.
                    Whether you're looking to build a new custom home or
                    renovate your current one, our team is dedicated to
                    delivering superior quality and craftsmanship.
                  </p>
                  <p>
                    We collaborate closely with you to understand your vision
                    and bring it to life, ensuring that every detail aligns with
                    your lifestyle and preferences. From modern architectural
                    designs to classic styles, we use premium materials and
                    innovative construction methods to achieve stunning and
                    sustainable results. With our commitment to excellence and
                    customer satisfaction, we turn your dream home into a
                    reality.
                  </p>
                </div>
                <div className="resi-image web">
                  <img
                    src={Residencial2}
                    alt="image"
                    height="95%"
                    width="75%"
                  />
                </div>
              </div>

              <div className="d-flex flex-wrap mt-5">
                <div className="image mobile mobile">
                  <img
                    src={Residencial3}
                    alt="residencial-image"
                    height="95%"
                    width="75%"
                  />
                </div>
                <div className="resi-image web">
                  <img
                    src={Residencial3}
                    alt="residencial-image"
                    height="95%"
                    width="75%"
                  />
                </div>
                <div className="para ">
                  <h3>Building Exceptional Homes Across the UK</h3>
                  <p>
                    At Chouhan Builders Midlands, we take pride in creating
                    beautiful, bespoke homes for families across the UK. Our
                    residential construction services are tailored to bring your
                    dream home to life, whether you’re planning a new build or a
                    renovation. We combine high-quality materials, innovative
                    techniques, and meticulous attention to detail to ensure
                    each project meets the highest standards of excellence. Our
                    experienced team works closely with you from concept to
                    completion, ensuring your vision is realized in every aspect
                    of the design and construction process.
                  </p>
                  <p>
                    {" "}
                    Trust Chouhan Builders to deliver a home that perfectly
                    suits your lifestyle and stands the test of time.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <hr className="mx-5" />

          {/* -----------------------------------------------------------Resiencial Ended------------------------------------------ */}

          {/* ---------------------------------------------Commercial---------------------------------------------- */}
          <section>
            <div className="container mt-5">
              <h1 className="mb-5 text-center">Commercial</h1>
              <div className="d-flex flex-wrap">
                <div className="image">
                  <img src={Commercial} alt="image" height="75%" width="80%" />
                </div>

                <div className="para">
                  <h3> Innovative Design Solutions</h3>
                  <p>
                    {" "}
                    Developing modern, functional commercial spaces that
                    optimize workflow, boost productivity, and reflect your
                    brand's identity.
                  </p>
                </div>
              </div>
              <br />

              <div className="d-flex flex-wrap">
                <div className="image mobile">
                  <img src={Commercial3} alt="image" height="75%" width="80%" />
                </div>
                <div className="para">
                  <h3> Sustainable Practices</h3>
                  <p>
                    {" "}
                    Utilizing eco-friendly materials and energy-efficient
                    technologies to construct environmentally responsible
                    buildings that reduce operational costs.
                  </p>
                </div>
                <div className="image web">
                  <img src={Commercial3} alt="image" height="75%" width="80%" />
                </div>
              </div>
              <br />

              <div className="d-flex flex-wrap">
                <div className="image">
                  <img src={Commercial2} alt="image" height="75%" width="80%" />
                </div>
                <div className="para">
                  <h3> Project Management Expertise</h3>
                  <p>
                    Ensuring seamless project delivery through meticulous
                    planning, skilled coordination, and adherence to timelines
                    and budgets, while maintaining the highest quality
                    standards.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* -------------------------------------------------Commercial Ended--------------------------------- */}
          <section className="pb-5 mt-5">
            <div className="container mt-5">
              <h2 className="text-center">
                OUR COMMERCIAL BUILDING SERVICES IN UK INCLUDE
              </h2>
              <div className="row mt-5">
                <div className="col-sm-2"></div>
                <div className="col-sm-4">
                  <ul className="fs-5">
                    <li>New build, construction</li>
                    <li>Building conversion</li>
                    <li>Building extensions</li>
                    <li>Bespoke joinery</li>
                  </ul>
                </div>
                <div className="vl col-sm-1 web"></div>
                <hr className="mobile"></hr>
                <div className="col-sm-4">
                  <ul className="fs-5">
                    <li>Office refurbishment/reconfiguring</li>
                    <li>School refurbishments</li>
                    <li>Places of Worship</li>
                  </ul>
                </div>
                <div className="col-sm-2"></div>
              </div>
            </div>
          </section>
          {/* <section>
  <footer>

  </footer>
</section> */}
        </div>
      </div>
    </>
  );
};

export default Home;
