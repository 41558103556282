import React, { useState, useEffect, useRef } from "react";
// import Card1 from "../Images/t1.jpg"
// import Card2 from "../Images/t2.jpg"
import Card3 from "../Images/o3.jpg";
import Card4 from "../Images/o7.jpg";
import Card5 from "../Images/o5.jpg";
import Card6 from "../Images/o8.jpg";
import Card7 from "../Images/o9.jpg";

import "./horizzontalscroll.css";

const HorizontalScrollCards = ({ cards, cardWidth, autoScrollSpeed }) => {
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      const scrollWidth = container.scrollWidth;
      const clientWidth = container.clientWidth;

      const startAutoScroll = () => {
        intervalRef.current = setInterval(() => {
          const newScrollLeft = container.scrollLeft + 1;
          if (newScrollLeft >= scrollWidth - clientWidth) {
            container.scrollLeft = 0;
          } else {
            container.scrollLeft = newScrollLeft;
          }
        }, autoScrollSpeed);
      };

      const stopAutoScroll = () => {
        clearInterval(intervalRef.current);
      };

      container.addEventListener("mouseenter", stopAutoScroll);
      container.addEventListener("mouseleave", startAutoScroll);

      startAutoScroll();

      return () => {
        container.removeEventListener("mouseenter", stopAutoScroll);
        container.removeEventListener("mouseleave", startAutoScroll);
        clearInterval(intervalRef.current);
      };
    }
  }, [autoScrollSpeed]);

  return (
    <div
      ref={containerRef}
      className="horizontal-scroll-container"
      style={{ overflowX: "auto", whiteSpace: "nowrap" }}
    >
      {cards.map((card, index) => (
        <div
          key={index}
          className="card"
          style={{
            display: "inline-block",
            width: cardWidth,
            margin: "0 10px",
          }}
        >
          {card}
        </div>
      ))}
    </div>
  );
};

const ScrollView = () => {
  const initialCards = [
    // { imageUrl: Card1, heading: 'Card 1' },
    // { imageUrl: Card2, heading: 'Card 2' },
    { imageUrl: Card3, heading: "Room" },
    { imageUrl: Card4, heading: "Floor" },
    { imageUrl: Card5, heading: "Bathroom" },
    { imageUrl: Card6, heading: "New Build" },
    { imageUrl: Card7, heading: "Groundwork's" },
  ];
  const [cardWidthHome, setCardWidthHome] = useState("30%");

  const handleResize = () => {
    if (window.innerWidth < 767) {
      setCardWidthHome("95%");
    } else {
      setCardWidthHome("30%");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const cards = [...initialCards, ...initialCards];
  return (
    <div className="mt-5">
      <HorizontalScrollCards
        cards={cards.map((card, index) => (
          <div key={index}>
            <img
              className="web"
              src={card.imageUrl}
              alt={card.heading}
              style={{ width: "100%", height: "400px" }}
            />
            <img
              className="mobile"
              src={card.imageUrl}
              alt={card.heading}
              style={{ width: "100%", height: "300px" }}
            />
            <h3 className="text-center">{card.heading}</h3>
          </div>
        ))}
        cardWidth={cardWidthHome}
        autoScrollSpeed={30}
      />
    </div>
  );
};

export default ScrollView;
